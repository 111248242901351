<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div>
            <div class="table-responsive table-desi">
              <table class="user-table coupon-list-table table table-striped">
                <thead>
                  <tr>
                    <th>
                      <span class="form-check user-checkbox">
                        <input
                          class="checkbox_animated"
                          type="checkbox"
                          value=""
                          id="checkall"
                        />
                      </span>
                    </th>
                    <th>Title</th>
                    <th>Code</th>
                    <th>Discount</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <span class="form-check user-checkbox">
                        <input
                          class="checkbox_animated check-it"
                          type="checkbox"
                          value=""
                        />
                      </span>
                    </td>
                    <td>10% Off</td>
                    <td>5488165</td>
                    <td class="theme-color">10%</td>
                    <td class="menu-status">
                      <span class="warning">Restitute</span>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span class="form-check user-checkbox">
                        <input
                          class="checkbox_animated check-it"
                          type="checkbox"
                          value=""
                        />
                      </span>
                    </td>
                    <td>25% Off</td>
                    <td>2143235</td>
                    <td class="theme-color">17%</td>
                    <td class="menu-status">
                      <span class="success">Success</span>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span class="form-check user-checkbox">
                        <input
                          class="checkbox_animated check-it"
                          type="checkbox"
                          value=""
                        />
                      </span>
                    </td>
                    <td>12% Off</td>
                    <td>3243468</td>
                    <td class="theme-color">20%</td>
                    <td class="menu-status">
                      <span class="success">Success</span>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span class="form-check user-checkbox">
                        <input
                          class="checkbox_animated check-it"
                          type="checkbox"
                          value=""
                        />
                      </span>
                    </td>
                    <td>45% Off</td>
                    <td>7846289</td>
                    <td class="theme-color">50%</td>
                    <td class="menu-status">
                      <span class="warning">Restitute</span>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span class="form-check user-checkbox">
                        <input
                          class="checkbox_animated check-it"
                          type="checkbox"
                          value=""
                        />
                      </span>
                    </td>
                    <td>45% Off</td>
                    <td>3614376</td>
                    <td class="theme-color">60%</td>
                    <td class="menu-status">
                      <span class="success">Success</span>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span class="form-check user-checkbox">
                        <input
                          class="checkbox_animated check-it"
                          type="checkbox"
                          value=""
                        />
                      </span>
                    </td>
                    <td>80% Off</td>
                    <td>8328192</td>
                    <td class="theme-color">45%</td>
                    <td class="menu-status">
                      <span class="success">Success</span>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span class="form-check user-checkbox">
                        <input
                          class="checkbox_animated check-it"
                          type="checkbox"
                          value=""
                        />
                      </span>
                    </td>
                    <td>60% Off</td>
                    <td>7218376</td>
                    <td class="theme-color">30%</td>
                    <td class="menu-status">
                      <span class="success">Success</span>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span class="form-check user-checkbox">
                        <input
                          class="checkbox_animated check-it"
                          type="checkbox"
                          value=""
                        />
                      </span>
                    </td>
                    <td>40% Off</td>
                    <td>1872349</td>
                    <td class="theme-color">42%</td>
                    <td class="menu-status">
                      <span class="success">Success</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- Pagination Start -->
        <div class="pagination-box">
          <nav class="ms-auto me-auto" aria-label="...">
            <ul class="pagination pagination-primary">
              <li class="page-item disabled">
                <a class="page-link" href="javascript:void(0)">Previous</a>
              </li>
              <li class="page-item active">
                <a class="page-link" href="javascript:void(0)">1</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">2</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">3</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">Next</a>
              </li>
            </ul>
          </nav>
        </div>
        <!-- Pagination End -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: "Rodela Crafts-COupon List",
};
</script>

<style></style>
