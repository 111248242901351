
<script setup>
import axios from 'axios';
import {ref, onMounted, watch} from 'vue'
import { useRoute, useRouter } from 'vue-router';

const router         = useRouter();
const {params}       = useRoute();
const stores         = ref([]);
const cities         = ref([]);
const zones          = ref([]);
const areas          = ref([]);


const loadingStore          = ref(false);
const loadingCity           = ref(false);
const loadingZone           = ref(false);
const loadingArea           = ref(false);
const loadingPathaoCost     = ref(false);
const loadingPathaoShipped  = ref(false);
const sender_name           = ref();
const sender_phone          = ref();
const recipient_name        = ref();
const recipient_phone       = ref();
const recipient_address     = ref();
const shop_store            = ref();
const cityId                = ref();
const zoneId                = ref();
const area_id               = ref();
const delivery_type         = ref();
const order_type            = ref();
const item_weight           = ref();
const collect_amount        = ref(0);
const special_instruction   = ref();
const item_description      = ref();
const pathao_cost           = ref();
const item_quantity         = ref();
const order_id              = ref();
const errors                = ref();

const getShippingInfo = async() =>{
    try {
        const res = await axios.get(`/admin/pathao/orders/shipped/${params.id}`);
        if(res.status===200){
            console.log(res);
            let orderData  = res.data.result.order
            let senderData = res.data.result.sender_info

            sender_name.value       = senderData.name
            sender_phone.value      = senderData.phone_number
            recipient_name.value    = orderData.customer_name
            recipient_phone.value   = orderData.phone_number
            recipient_address.value = orderData.address_details
            item_quantity.value     = orderData.items_count
            order_id.value          = orderData.id
            collect_amount.value    = Number(orderData.payable_price)
        }
    } catch (error) {
        console.log(error);
    }
}

const getStores = async() =>{
    loadingStore.value = true;
    try {
        const res = await axios.get('/admin/pathao/stores');
        if(res.status===200){
            stores.value = res.data.result.data.data
        }
    } catch (error) {
        console.log(error);
    }finally{
        loadingStore.value = false;
    }
}
const getCities = async() =>{
    loadingCity.value = true
    loadingZone.value = true
    loadingArea.value = true
    try {
        const res = await axios.get('/admin/pathao/cities');
        if(res.status===200){
            cities.value = res.data.result.data.data
        }
    } catch (error) {
        console.log(error);
    }finally{
        loadingCity.value = false;
        loadingZone.value = false;
        loadingArea.value = false;
    }
}
const getZones = async(cityId) =>{
    loadingZone.value = true
    loadingArea.value = true
    try {
        const res = await axios.get(`/admin/pathao/zones/${cityId}`);
        if(res.status===200){
            zones.value = res.data.result.data.data
        }
    } catch (error) {
        console.log(error);
    }finally{
        loadingZone.value = false;
        loadingArea.value = false;
    }
}
const getAreas = async(zoneId) =>{
    loadingArea.value = true;
    try {
        const res = await axios.get(`/admin/pathao/areas/${zoneId}`);
        if(res.status===200){
            areas.value = res.data.result.data.data
        }
    } catch (error) {
        console.log(error);
    }finally{
        loadingArea.value = false
    }
}

const pathaoCostCalculation = async() =>{
    loadingPathaoCost.value = true;
    try {
        const res = await axios.post('/admin/pathao/cost/calculate', {
            store_id         : shop_store.value,
            recipient_city_id: cityId.value,
            recipient_zone_id: zoneId.value,
            delivery_type    : delivery_type.value,
            item_weight      : item_weight.value,
            item_type        : order_type.value,
        });
        if(res.status===200){
            pathao_cost.value = res.data.result.data.price
        }
    } catch (error) {
        console.log(error);
    }finally{
        loadingPathaoCost.value = false
    }
}

const submit = async() =>{
    loadingPathaoShipped.value = true;
    try {
        const res = await axios.post('/admin/pathao/orders/shipped',{
            store_id           : shop_store.value,
            order_id           : order_id.value,
            sender_name        : sender_name.value,
            sender_phone       : sender_phone.value,
            recipient_name     : recipient_name.value,
            recipient_phone    : recipient_phone.value,
            recipient_address  : recipient_address.value,
            recipient_city_id  : cityId.value,
            recipient_zone_id  : zoneId.value,
            recipient_area_id  : area_id.value,
            delivery_type      : delivery_type.value,
            item_type          : order_type.value,
            special_instruction: special_instruction.value,
            item_quantity      : item_quantity.value,
            item_weight        : item_weight.value,
            collect_amount     : collect_amount.value,
            item_description   : item_description.value,
        });
        if(res.status===200){
            router.push({name:'order_list'});
        }else{
            errors.value = res.data.message;
        }
    } catch (error) {
        console.log(error);
    }finally{
        loadingPathaoShipped.value = false;
    }
}


watch(
  [
    () => shop_store.value,
    () => cityId.value,
    () => zoneId.value,
    () => area_id.value,
    () => delivery_type.value,
    () => item_weight.value,
    () => order_type.value
  ],
  ([storeId, cityId, zoneId, areaId, deliveryType, itemWeight, orderType]) => {
    pathaoCostCalculation();
  }
);


onMounted(() => {
    getStores();
    getCities();
    getShippingInfo();
})
</script>



<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="bg-inner cart-section order-details-table">
            <div class="row">
                <div class="col-md-12">
                    <div class="card-header-2 d-flex justify-content-between">
                        <h5>Shipped Pathao Information</h5>
                        <span>
                            <router-link :to="{name:'order_list'}" class="btn btn-info me-2"><i class="fa fa-arrow-left"></i> Back</router-link>
                            <router-link :to="{name:'create_store', params:{id : params.id}}" class="btn btn-info me-2"> Create Store</router-link>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-3 box-shadow">
                    <div class="mb-4">
                        <label for="">Sender Name</label>
                        <input type="text" class="form-control" placeholder="Enter Customer District Name" v-model="sender_name" disabled>
                    </div>
                    <div class="mb-4">
                        <label for="">Sender Phone</label>
                        <input type="text" class="form-control" placeholder="Enter Customer District Name" v-model="sender_phone">
                        <span v-if="errors">
                            <span class="text-danger" v-for="(error, index) in errors.sender_phone" :key="index">{{error}}</span>
                        </span>
                    </div>
                    <div class="mb-4">
                        <label for="">Recipient Name</label>
                        <input type="text" class="form-control" placeholder="Recipient Name" v-model="recipient_name">
                        <span v-if="errors">
                            <span class="text-danger" v-for="(error, index) in errors.recipient_name" :key="index">{{error}}</span>
                        </span>
                    </div>
                    <div class="mb-4">
                        <label for="">Recipient Number</label>
                        <input type="text" class="form-control" placeholder="0100 000 0000" v-model="recipient_phone">
                        <span v-if="errors">
                            <span class="text-danger" v-for="(error, index) in errors.recipient_phone" :key="index">{{error}}</span>
                        </span>
                    </div>
                    <div class="mb-4">
                        <label for="">Recipient Address</label>
                        <input type="text" class="form-control" placeholder="Enter Recipient Address" v-model="recipient_address">
                        <span v-if="errors">
                            <span class="text-danger" v-for="(error, index) in errors.recipient_address" :key="index">{{error}}</span>
                        </span>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="row">
                        <div class="col-md-6 mb-4">
                            <label for="">Store <span class="text-danger">*</span></label>
                            <div v-if="loadingStore">
                                <span class="loadingBox"><i class="fas fa-spinner fa-spin"></i> Loading... </span>
                            </div>
                            <select class="form-control" v-model="shop_store" v-else>
                                <option v-for="(store, index) in stores" :key="index" :value="store.store_id">{{ store.store_name }}</option>
                            </select>
                            <span v-if="errors">
                                <span class="text-danger" v-for="(error, index) in errors.store_id" :key="index">{{error}}</span>
                            </span>
                        </div>
                        <div class="col-md-6 mb-4">
                            <label for="">Recipient City <span class="text-danger">*</span></label>
                            <div v-if="loadingCity">
                                <span class="loadingBox"><i class="fas fa-spinner fa-spin"></i> Loading... </span>
                            </div>
                            <select class="form-control" v-model="cityId" @change="getZones(cityId)" v-else>
                                <option v-for="(city, index) in cities" :key="index" :value="city.city_id">{{ city.city_name }}</option>
                            </select>
                            <span v-if="errors">
                                <span class="text-danger" v-for="(error, index) in errors.recipient_city_id" :key="index">{{error}}</span>
                            </span>
                        </div>
                        <div class="col-md-6 mb-4">
                            <label for="">Recipient Zone <span class="text-danger">*</span></label>
                            <div v-if="loadingZone">
                                <span class="loadingBox"><i class="fas fa-spinner fa-spin"></i> Loading... </span>
                            </div>
                            <select class="form-control" v-model="zoneId" @change="getAreas(zoneId)" v-else>
                                <option v-for="(zone, index) in zones" :key="index" :value="zone.zone_id">{{ zone.zone_name }}</option>
                            </select>
                            <span v-if="errors">
                                <span class="text-danger" v-for="(error, index) in errors.recipient_zone_id" :key="index">{{error}}</span>
                            </span>
                        </div>
                        <div class="col-md-6 mb-4">
                            <label for="">Recipient Area<span class="text-danger">*</span></label>
                            <div v-if="loadingArea">
                                <span class="loadingBox"><i class="fas fa-spinner fa-spin"></i> Loading... </span>
                            </div>
                            <select class="form-control" v-model="area_id" v-else>
                                <option v-for="(area, index) in areas" :key="index" :value="area.area_id">{{ area.area_name }}</option>
                            </select>
                            <span v-if="errors">
                                <span class="text-danger" v-for="(error, index) in errors.area_id" :key="index">{{error}}</span>
                            </span>
                        </div>
                        <div class="col-md-6 mb-4">
                            <label for="">Delivery Type<span class="text-danger">*</span></label>
                            <select class="form-control" v-model="delivery_type">
                                <option value="48">Normal</option>
                                <option value="12">On Demand</option>
                            </select>
                            <span v-if="errors">
                                <span class="text-danger" v-for="(error, index) in errors.delivery_type" :key="index">{{error}}</span>
                            </span>
                        </div>
                        <div class="col-md-6 mb-4">
                            <label for="">Item Type<span class="text-danger">*</span></label>
                            <select class="form-control" v-model="order_type">
                                <option value="2">Percel</option>
                                <!-- <option value="1">Document</option> -->
                            </select>
                            <span v-if="errors">
                                <span class="text-danger" v-for="(error, index) in errors.item_type" :key="index">{{error}}</span>
                            </span>
                        </div>
                        <div class="col-md-6 mb-4">
                            <label for="">Items Weight (0.5kg - 10kg)<span class="text-danger">*</span></label>
                            <input type="number" class="form-control" placeholder="Max Weight 10kg" v-model="item_weight">
                            <span v-if="errors">
                                <span class="text-danger" v-for="(error, index) in errors.item_weight" :key="index">{{error}}</span>
                            </span>
                        </div>
                        <div class="col-md-6 mb-4">
                            <label for="">Collect Amount<span class="text-danger">*</span></label>
                            <input type="number" class="form-control" v-model="collect_amount">
                            <span v-if="errors">
                                <span class="text-danger" v-for="(error, index) in errors.collect_amount" :key="index">{{error}}</span>
                            </span>
                        </div>
                        <div class="col-md-6 mb-4">
                            <label for="">Special Instruction (Optional)</label>
                            <textarea class="form-control" v-model="special_instruction" cols="30" rows="2"></textarea>
                        </div>
                        <div class="col-md-6 mb-4">
                            <label for="">Item Description (Optional)</label>
                            <textarea class="form-control" v-model="item_description" cols="30" rows="2"></textarea>
                        </div>
                        <div class="col-md-6 mb-4">
                            <label for="">Patho Cost <span class="text-danger">*</span></label>
                            <div v-if="loadingPathaoCost">
                                <span class="loadingBox"><i class="fas fa-spinner fa-spin"></i> Loading... </span>
                            </div>
                            <input type="text" class="form-control" placeholder="Pathao Cost" v-model="pathao_cost" disabled v-else>
                            <span v-if="errors">
                                <span class="text-danger" v-for="(error, index) in errors.pathao_cost" :key="index">{{error}}</span>
                            </span>
                        </div>
                        <div class="col-md-6 mb-4">
                            <label for="">Item Quantity <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="Enter Item Quantity" v-model="item_quantity">
                            <span v-if="errors">
                                <span class="text-danger" v-for="(error, index) in errors.item_quantity" :key="index">{{error}}</span>
                            </span>
                        </div>
                        <div class="col-md-12 mb-4 d-flex justify-content-end align-items-end">
                            <div v-if="loadingPathaoShipped">
                                <button class="btn btn-info"><i class="fas fa-spinner fa-spin"></i> Loading...</button>
                            </div>
                            <div v-else>
                                <button class="btn btn-info" @click="submit">Shipped to Pathao</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <!-- section end -->
        </div>
      </div>
    </div>
  </div>
</template>

<style>
    .box-shadow{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 20px;
        padding: 40px 30px;
    }
    .loadingBox{
        border:1px solid #d1d1d1;
        border-radius:5px;
        width:100%;
        padding:7px 12px;
    }
</style>
