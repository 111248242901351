
<script>
export default {
  title: "Rodela Crafts-Log In",
  data() {
    return {
      form: {
        phone_number: "",
        password: "",
      },
      isLoading: false,
    };
  },
  methods: {
    handleLogin() {
      // this.submitted = false;
      this.isLoading = true;
      this.$store.dispatch("functionalities/LOGIN", this.form)
      .then(res => {
          if (res.data.success) {
            this.$router.push({name:'dashboard'});
          } else {
              // this.isLoading = false;
              // this.errors = res.data.msg;
              // if (typeof this.errors !== 'object') {
              //     this.not_match = res.data.msg;
              // }
          }
      })
      .catch(err => {
          console.log(err);
      })
      .finally(() => {
          this.isLoading = false;
      });
    },
    handleFocusOut(field) {
      this.values[field] === "" && (this.selected[field] = false);
    },
    select(textbox) {
      this.selected[textbox] = true;
    },
  },
};
</script>


<template>
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-12 p-0">
        <div class="login-section">
          <div class="materialContainer">
            <div class="box">
              <div class="login-title">
                <h2>Login</h2>
              </div>
              <div class="input">
                <label for="phone_number" style="top:-7px;">Phone Number</label>
                <input type="text" id="phone_number" placeholder="Enter Phone Number" v-model="form.phone_number" />
              </div>
              <div class="input">
                <label for="password" style="top:-7px;">Password</label>
                <input type="password" id="password" placeholder="Enter Password" v-model="form.password"/>
              </div>
              <a class="pass-forgot">Forgot your password?</a>
              <div class="button login">
                <button type="submit" @click.prevent="handleLogin" v-if="isLoading">
                  <span><i class="fas fa-spinner fa-spin"></i> Loading...</span>
                </button>
                <button type="submit" @click.prevent="handleLogin" v-else>
                  <span>Log In</span>
                  <i class="fa fa-check"></i>
                </button>
              </div>

              <!-- <p class="sign-category">
                <span>Or sign in with</span>
              </p>

              <div class="row gx-md-3 gy-3">
                <div class="col-md-6">
                  <a href="https://www.facebook.com/" target="_blank">
                    <div class="social-media fb-media">
                      <img
                        src="@/assets/images/facebook.png"
                        class="img-fluid"
                        alt=""
                      />
                      <h6>Facebook</h6>
                    </div>
                  </a>
                </div>
                <div class="col-md-6">
                  <a href="https://mail.google.com/" target="_blank">
                    <div class="social-media google-media">
                      <img
                        src="@/assets/images/google.png"
                        class="img-fluid"
                        alt=""
                      />
                      <h6>Google</h6>
                    </div>
                  </a>
                </div>
              </div>

              <p>Not a member? <span>Sign up</span></p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .input input:focus{
    outline: none;
  }
</style>


