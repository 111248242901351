<script setup>
import axios from 'axios';
import {ref, computed, reactive} from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const reports  = ref([]);
const formData = reactive({
    start_date: '',
    end_date: '',
    action: '',
})

const orderReports = async() =>{
    try {
        const res = await axios.get(`admin/orders/report`, { params: formData});
        reports.value = res.data.result
    } catch (error) {
        console.log(error);
    }
}

const orderExport = async() =>{
       const url = `https://api.rodelacrafts.com/api/admin/orders/report?start_date=${formData.start_date}&end_date=${formData.end_date}&action=export`;

       location.href=url;
} 

const amount = computed(() => {
    var buy_price = reports.value.reduce((total, report) => {
        return total + parseFloat(report.buy_price || 0);
    }, 0).toFixed(2);
   
    var mrp = reports.value.reduce((total, report) => {
        return total + parseFloat(report.mrp || 0);
    }, 0).toFixed(2);
   
    var discount = reports.value.reduce((total, report) => {
        return total + parseFloat(report.discount || 0);
    }, 0).toFixed(2);

    var sell_price = reports.value.reduce((total, report) => {
        return total + parseFloat(report.sell_price || 0);
    }, 0).toFixed(2);

    var delivery_charge = reports.value.reduce((total, report) => {
        return total + parseFloat(report.delivery_charge || 0);
    }, 0).toFixed(2);

    var special_discount = reports.value.reduce((total, report) => {
        return total + parseFloat(report.special_discount || 0);
    }, 0).toFixed(2);

    var payable_price = reports.value.reduce((total, report) => {
        return total + parseFloat(report.payable_price || 0);
    }, 0).toFixed(2);

    return  {
        buy_price : buy_price,
        mrp : mrp,
        discount : discount,
        sell_price : sell_price,
        delivery_charge : delivery_charge,
        special_discount : special_discount,
        payable_price : payable_price,
        // discount_price : totalDiscount,
    };
});


</script>

<template>
    <div class="table-responsive table-desi">
        <div class="row">
            <div class="col-md-12 d-flex justify-content-between">
            <div class="d-flex">
                <div class="me-2">
                    <label for="">Form Data :</label>
                    <input type="date" class="form-control mb-2" placeholder="Search Order . . ." v-model="formData.start_date">
                </div>
                <div>
                    <label for="">To Data :</label>
                    <input type="date" class="form-control mb-2" placeholder="Search Order . . ." v-model="formData.end_date">
                </div>
            </div>
                <div class="d-flex align-items-end mb-2">
                    <button class="btn btn-info me-2" @click.prevent="orderReports">Filter</button>
                    <!-- <a :href="`/api/admin/orders/report?start_date=${formData.start_date}&end_date=${formData.end_date}&action=export`" class="btn btn-dark">Export</a> -->
                    <button class="btn btn-dark me-2" @click.prevent="orderExport">Export</button>
                    <!-- <button class="btn btn-danger me-2">Clear</button> -->
                </div>
            </div>
        </div>
        <table class="table table-bordered">
            <thead>
                <tr class="bg-dark">
                    <th class="text-light" scope="col">SL</th>
                    <th class="text-light" scope="col">Order Id</th>
                    <th class="text-light" scope="col">Phone</th>
                    <th class="text-light" scope="col">Date</th>
                    <th class="text-light" scope="col">Customer Name</th>
                    <th class="text-light" scope="col">Buy Price</th>
                    <th class="text-light" scope="col">Mrp Price</th>
                    <th class="text-light" scope="col">Discount</th>
                    <th class="text-light" scope="col">Sell Price</th>
                    <th class="text-light" scope="col">Delivery Charge</th>
                    <th class="text-light" scope="col">Special Discount</th>
                    <th class="text-light" scope="col">Total</th>
                </tr>
            </thead>
            <tbody class="mb-5">
                <tr v-for="(report, index) in reports" :key="index">
                    <th scope="row">{{index+1}}</th>
                    <td>{{ report.id }}</td>
                    <td>{{ report.phone_number }}</td>
                    <td>{{ report.created_at }}</td>
                    <td>{{ report.customer_name }}</td>
                    <td>{{ report.buy_price }}</td>
                    <td>{{ report.mrp }}</td>
                    <td>{{ report.discount }}</td>
                    <td>{{ report.sell_price }}</td>
                    <td>{{ report.delivery_charge }}</td>
                    <td>{{ report.special_discount }}</td>
                    <td>{{ report.payable_price }}</td>
                </tr>
                <tr>
                    <th scope="row">#</th>
                    <td style="font-weight:700;" colspan="4">Total =</td>
                    <td style="font-weight:700;">{{ amount.buy_price }} tk.</td>
                    <td style="font-weight:700;">{{ amount.mrp }} tk.</td>
                    <td style="font-weight:700;">{{ amount.discount }} tk.</td>
                    <td style="font-weight:700;">{{ amount.sell_price }} tk.</td>
                    <td style="font-weight:700;">{{ amount.delivery_charge }} tk.</td>
                    <td style="font-weight:700;">{{ amount.special_discount }} tk.</td>
                    <td style="font-weight:700;">{{ amount.payable_price }} tk.</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style>
    .table-bordered thead, .table-bordered tbody, .table-bordered tfoot, .table-bordered tr, .table-bordered td, .table-bordered th {
        border-color: #7687e4;
    }
</style>