
<script setup>
import axios from "axios";
import {onMounted, ref} from 'vue'; 
import router from "@/router";
import { useRoute } from "vue-router";

const {params}        = useRoute();
const displayName     = ref('');
const description     = ref('');
const permissionsData = ref([]);
const permissions     = ref([]);
const errors          = ref();

const getRole = async() =>{
    try {
        const response = await axios.get(`/admin/roles/${params.id}`);
              displayName.value = response.data.result.display_name
              description.value = response.data.result.description
        const rolePermision     = response.data.result.permissions.map(s => s.id);
              permissions.value = rolePermision;
    } catch (error) {
        
    }
}
const getPermission = async() =>{
    try {
        const res = await axios.get('/admin/permissions');
        permissionsData.value = res.data.result.data
    } catch (error) {
        
    }
}

const submit = async() =>{
    try {
        const formData = new FormData();
        formData.append('display_name', displayName.value)
        formData.append('description', description.value)
        formData.append('_method', 'put')
      
        for (const permission of permissions.value) {
        formData.append('permission_ids[]', permission);
        }

        const responseRole = await axios.post(`/admin/roles/${params.id}`,formData);
        if(responseRole.status === 200){
          router.push({ name: 'roles' });
        }else{
          errors.value = responseRole.data.message
        }

    } catch (error) {
        
    }
}

onMounted(() => {
    getRole();
    getPermission();
})

</script>




<template>
  <div class="tab-pane fade show active" id="pills-home" role="tabpanel">
    <form class="theme-form theme-form-2 mega-form" @submit.prevent="submit">
      <div class="card-header-1 d-flex justify-content-between mb-4">
        <h3><strong>Role Information</strong></h3>
        <router-link :to="{name:'roles'}" class="btn btn-info"><i class="fa fa-arrow-left"></i> Back</router-link>
      </div>

      <div class="row">
        <div class="mb-4 row align-items-center">
          <label class="form-label-title col-lg-2 col-md-2 mb-0" >Role Display Name</label>
          <div class="col-md-10 col-lg-10">
            <input class="form-control" type="text" v-model="displayName"/>
            <span v-if="errors">
              <span class="text-danger" v-for="(error, index) in errors.display_name" :key="index">{{error}}</span>
            </span>
          </div>
        </div>

        <div class="mb-4 row align-items-center">
          <label class="col-lg-2 col-md-3 col-form-label form-label-title">Role Description</label>
          <div class="col-md-9 col-lg-10">
            <textarea class="form-control" name="" id="" cols="30" rows="3" v-model="description"></textarea>
          </div>
        </div>
        <div class="mb-4 row align-items-center">
        <label class="form-label-title col-sm-2 mb-0">Role Permissions</label>
            <div class="col-sm-10">
                <span v-for="(permission, index) in permissionsData" :key="index" style="margin-right:16px;">
                  <input :id="`permission`+permission.id" style="margin-right:5px;" type="checkbox" name="sell_price" :value="permission.id" v-model="permissions"/>
                  <label :for="`permission`+permission.id"> {{ permission.display_name }}</label>
                </span>
                <span v-if="errors">
                  <span class="text-danger" v-for="(error, index) in errors.permission_ids" :key="index">{{error}}</span>
                </span>
            </div>
        </div>

        <div class="row mb-4">
            <button class="permission-btn">Update Role</button>
        </div>
      </div>
    </form>
  </div>
</template>

<style>
.permission-btn{
    background-color: #06adb9;
    color: #fff;
    padding: 8px 25px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .permission-btn:hover{
    background-color: #038c96;
  }
</style>
