<script setup>
import axios from 'axios';
import {onMounted, ref} from 'vue';
import router from "@/router";
import { useRoute } from "vue-router";

const name    = ref('');
const status  = ref('');
const route   = useRoute();
const errors  = ref({});
const loading = ref(false);

const getSize = async() => {
    const res = await axios.get(`admin/sizes/${route.params.id}`);
    name.value   = res.data.result.name
    status.value = res.data.result.status
}
const submit = async() => {
  loading.value = true;
  try {
    const formData = new FormData();
    formData.append('name', name.value);
    formData.append('status', status.value);
    formData.append('_method', 'put');
    const response = await axios.post(`admin/sizes/${route.params.id}`, formData);
    if(response.data.success){
      name.value = '';
      router.push({ name: 'products_sizes' }); // Use router.push to navigate
    }else {
      errors.value = response.data.message;
    }
  } catch (error) {
    console.log(error);
  }finally{
    loading.value = false;
  }
}

onMounted(() => {
  getSize();
});
</script>


<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="card-header-2 d-flex justify-content-between">
                <h5>Size Information</h5>
                <router-link :to="{name:'products_sizes'}" class="btn btn-info"><i class="fa fa-arrow-left"></i> Back</router-link>
              </div>

              <form class="theme-form theme-form-2 mega-form" enctype="multipart/form-data" method="put" @submit.prevent="submit">
                <div class="row">
                  <div class="mb-4 row align-items-center">
                    <label class="form-label-title col-sm-2 mb-0" >Size Name</label >
                    <div class="col-sm-4">
                      <input class="form-control" name="name" type="text" placeholder="size Name" v-model="name"/>
                      <span v-if="errors.name" class="text-danger">{{ errors.name[0] }}</span>
                    </div>

                    <label class="col-sm-2 col-form-label form-label-title" >Size Status</label >
                    <div class="col-sm-4">
                      <select class="js-example-basic-single w-100" name="status" v-model="status" >
                        <option disabled>Select Size Status</option>
                        <option value="active" selected>Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <button class="size-btn" v-if="loading"><i class="fas fa-spinner fa-spin"></i> Loading...</button>
                  <button class="size-btn" v-else>Update Size</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
  .size-btn{
    background-color: #06adb9;
    color: #fff;
    padding: 8px 25px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .size-btn:hover{
    background-color: #038c96;
  }
  .theme-form select{
    border: none;
  }
</style>
