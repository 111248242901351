<template>
  <div class="title-header">
    <h5>{{ breadcrumbs.title }}</h5>
  </div>
</template>

<script>
export default {
  props: ["breadcrumbs"],
};
</script>

<style></style>
