<template>
  <div class="tab-pane fade show active" id="pills-usage" role="tabpanel">
    <form class="theme-form theme-form-2 mega-form">
      <div class="card-header-1">
        <h5>Usage Limits</h5>
      </div>

      <div class="row">
        <div class="mb-4 row align-items-center">
          <label class="form-label-title col-lg-2 col-md-3 mb-0"
            >Per Limited</label
          >
          <div class="col-md-9 col-lg-10">
            <input class="form-control" type="number" />
          </div>
        </div>

        <div class="row align-items-center">
          <label class="form-label-title col-lg-2 col-md-3 mb-0"
            >Per Customer</label
          >
          <div class="col-md-9 col-lg-10">
            <input class="form-control" type="number" />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
