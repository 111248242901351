<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-sm-12">
          <!-- Details Start -->
          <div class="card">
            <div class="card-body">
              <form class="theme-form theme-form-2 mega-form">
                <div class="row">
                  <div class="mb-4 row align-items-center">
                    <label class="form-label-title col-sm-2 mb-0"
                      >First Name</label
                    >
                    <div class="col-sm-10">
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Enter Your First Name"
                      />
                    </div>
                  </div>

                  <div class="mb-4 row align-items-center">
                    <label class="form-label-title col-sm-2 mb-0"
                      >Last Name</label
                    >
                    <div class="col-sm-10">
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Enter Your Last Name"
                      />
                    </div>
                  </div>

                  <div class="mb-4 row align-items-center">
                    <label class="form-label-title col-sm-2 mb-0"
                      >Your Phone Number</label
                    >
                    <div class="col-sm-10">
                      <input
                        class="form-control"
                        type="number"
                        placeholder="Enter Your Number"
                      />
                    </div>
                  </div>

                  <div class="mb-4 row align-items-center">
                    <label class="form-label-title col-sm-2 mb-0"
                      >Enter Email Address</label
                    >
                    <div class="col-sm-10">
                      <input
                        class="form-control"
                        type="email"
                        placeholder="Enter Your Email Address"
                      />
                    </div>
                  </div>

                  <div class="mb-4 row align-items-center">
                    <label class="col-sm-2 col-form-label form-label-title"
                      >Photo</label
                    >
                    <div class="col-sm-10">
                      <input
                        class="form-control form-choose"
                        type="file"
                        id="formFileMultiple"
                        multiple
                      />
                    </div>
                  </div>

                  <div class="mb-4 row align-items-center">
                    <label class="form-label-title col-sm-2 mb-0"
                      >Password</label
                    >
                    <div class="col-sm-10">
                      <input
                        class="form-control"
                        type="password"
                        placeholder="Enter Your Password"
                      />
                    </div>
                  </div>

                  <div class="mb-4 row align-items-center">
                    <label class="form-label-title col-sm-2 mb-0"
                      >Confirm Password</label
                    >
                    <div class="col-sm-10">
                      <input
                        class="form-control"
                        type="password"
                        placeholder="Enter Your Confirm Passowrd"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- Details End -->

          <!-- Address Start -->
          <div class="card">
            <div class="card-body">
              <div class="card-header-2 mb-3">
                <h5>Address</h5>
              </div>

              <div class="save-details-box">
                <div class="row g-4">
                  <div class="col-xl-4 col-md-6">
                    <div class="save-details">
                      <div class="save-name">
                        <h5>Mark Jugal</h5>
                      </div>

                      <div class="save-position">
                        <h6>Home</h6>
                      </div>

                      <div class="save-address">
                        <p>549 Sulphur Springs Road</p>
                        <p>Downers Grove, IL</p>
                        <p>60515</p>
                      </div>

                      <div class="mobile">
                        <p class="mobile">Mobile No. +1-123-456-7890</p>
                      </div>

                      <div class="button">
                        <a href="javascript:void(0)" class="btn btn-sm">Edit</a>
                        <a href="javascript:void(0)" class="btn btn-sm"
                          >Remove</a
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-4 col-md-6">
                    <div class="save-details">
                      <div class="save-name">
                        <h5>Method Zaki</h5>
                      </div>

                      <div class="save-position">
                        <h6>Office</h6>
                      </div>

                      <div class="save-address">
                        <p>549 Sulphur Springs Road</p>
                        <p>Downers Grove, IL</p>
                        <p>60515</p>
                      </div>

                      <div class="mobile">
                        <p class="mobile">Mobile No. +1-123-456-7890</p>
                      </div>

                      <div class="button">
                        <a href="javascript:void(0)" class="btn btn-sm">Edit</a>
                        <a href="javascript:void(0)" class="btn btn-sm">
                          Remove</a
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-4 col-md-6">
                    <div class="save-details">
                      <div class="save-name">
                        <h5>Mark Jugal</h5>
                      </div>

                      <div class="save-position">
                        <h6>Home</h6>
                      </div>

                      <div class="save-address">
                        <p>549 Sulphur Springs Road</p>
                        <p>Downers Grove, IL</p>
                        <p>60515</p>
                      </div>

                      <div class="mobile">
                        <p class="mobile">Mobile No. +1-123-456-7890</p>
                      </div>

                      <div class="button">
                        <a href="javascript:void(0)" class="btn btn-sm">Edit</a>
                        <a href="javascript:void(0)" class="btn btn-sm"
                          >Remove</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Address End -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: "Rodela Crafts-Profile Setting",
};
</script>

<style></style>
