
<script setup>
  import { Bootstrap5Pagination } from 'laravel-vue-pagination';
  import axios from 'axios';
  import {ref, onMounted, reactive} from 'vue';

  const productsData = ref([]);
  const pName        = ref('');
  const paginate_size= ref(20);

  const getProducts = async(page = 1) => {
    try{
      const res = await axios.get(`/admin/products`,{
        params:{
          page:page,
          paginate_size:paginate_size.value,
          name:pName.value
        }
      });
      productsData.value = res.data.result
    }catch(error){
      console.log(error);
    }
  }

  const searchProduct = () =>{
    getProducts();
  }

  const paginateSizeWizeProduct = () =>{
    getProducts();
  }
    const deleteProduct = async(id) =>{
    const res = await axios.post(`/admin/products/${id}`);
    getProducts();
  }

const getMinPrice = (product_prices) =>{
  const prices = product_prices.map(s=>s.pivot.offer_price==0?s.pivot.mrp:s.pivot.offer_price)
  const minMrp = Math.min(...prices);
  const maxMrp = Math.max(...prices);
  return {
    'minPrice' :minMrp,
    'maxPrice' :maxMrp,
  }
}

  onMounted(() => {
    getProducts();
  });

</script>



<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="row d-flex justify-content-between">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-10">
                  <label for="search">Search Your Product</label>
                  <input type="text" class="form-control mb-2" id="search" placeholder="Search Product . . ." @input="searchProduct" v-model="pName">
                </div>
                <div class="col-md-2">
                  <label for="">Per page show</label>
                  <select v-model="paginate_size" class="form-control" @change="paginateSizeWizeProduct">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex justify-content-end align-items-end">
              <span>
                 <router-link :to="{name:'add_product'}" class="btn btn-info mb-2" >Add New Product</router-link>
              </span>
            </div>
          </div>
          <div>
            <div class="table-responsive table-desi table-product">
              <table class="table table-bordered">
                <thead>
                  <tr class="bg-dark">
                    <th class="text-light" style="width: 5%;font-size:15px;">SL</th>
                    <th class="text-light" style="width: 5%;font-size:15px;">Image</th>
                    <th class="text-light" style="width: 20%;font-size:15px;">Product Name</th>
                    <th class="text-light" style="width: 12%;font-size:15px;">Category</th>
                    <th class="text-light" style="width: 10%;font-size:15px;">Brand</th>
                    <th class="text-light" style="width: 8%;font-size:15px;">SKU</th>
                    <th class="text-light" style="width: 10%;font-size:15px;">Type</th>
                    <th class="text-light" style="width: 5%;font-size:15px;">Qty</th>
                    <th class="text-light" style="width: 10%;font-size:15px;">Price</th>
                    <th class="text-light" style="width: 5%;font-size:15px;">Status</th>
                    <th class="text-light" style="width: 10%;font-size:15px;">Option</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(product, index) in productsData.data" :key="index">
                    <td>{{index+1}}</td>
                    <td>
                      <img
                        :src="product.image"
                        class="img-fluid"
                        alt=""
                        width="50"
                      />
                    </td>

                    <td>
                      <a>{{product.name}}</a>
                    </td>

                    <td>
                      <a href="javascript:void(0)">{{ product.category.name }}</a>
                    </td>
                    <td>
                      <a href="javascript:void(0)" v-if="product.brand">{{ product.brand.name }}</a>
                    </td>
                    <td>
                      <a href="javascript:void(0)" v-if="product.sku">{{ product.sku }}</a>
                    </td>
                    <td>
                      <a href="javascript:void(0)" v-if="product.type=='top-product'">Top</a>
                      <a href="javascript:void(0)" v-if="product.type=='feature-product'">Featured</a>
                      <a href="javascript:void(0)" v-if="product.type=='recent-product'">New</a>
                    </td>

                    <td>{{ product.current_stock }}</td>

                    <!-- <td class="td-price"><del class="text-danger" v-if="product.offer_price != 0">{{product.mrp}} tk </del><br> <span class="text-success"> {{product.offer_price != 0 ? product.offer_price : product.mrp}} tk</span></td> -->
                    
                    <td v-if="product.product_prices.length > 0">{{getMinPrice(product.product_prices).minPrice}} tk - {{getMinPrice(product.product_prices).maxPrice}} tk</td>
                    <td v-else><del class="text-danger" v-if="product.offer_price != 0">{{product.mrp}} tk </del><br> <span class="text-success"> {{product.offer_price != 0 ? product.offer_price : product.mrp}} tk</span></td>

                    <td class="td-cross" v-if="product.status=='inactive'">
                      <span class="lnr lnr-cross-circle"></span>
                    </td>
                    <td class="td-check" v-else>
                      <span class="lnr lnr-checkmark-circle"> </span>
                    </td>

                    <td>
                      <ul class="product-action">
                        <li>
                          <router-link :to="{name:'update.product', params:{ id: product.id}}"> <i class="fas fa-edit text-success"></i> </router-link>
                        </li>
                        <li>
                          <span @click="deleteProduct(product.id)"> <i class="fas fa-trash text-danger"></i> </span>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="pagination-box me-5 mb-5">
          <nav class="ms-auto me-auto" aria-label="...">
            <ul class="pagination pagination-primary">
              <Bootstrap5Pagination
                  :data="productsData"
                  @pagination-change-page="getProducts"
              />
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>



<style>
  .product-action li{
    display: inline-block;
    margin-right: 15px;
  }
</style>
