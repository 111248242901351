<script setup>

import axios from "axios";
import {ref, onMounted} from 'vue';

const users = ref([]);

const getUsers = async() =>{
  try {
    const res = await axios.get('/admin/users');
    users.value = res.data.result.data
  } catch (error) {
    console.log(error);
  }
}

onMounted(() => {
  getUsers();
})

</script>



<template>
  <div>
    <div class="table-responsive table-desi">
      <table class="user-table table table-striped">
        <thead>
          <tr>
            <th>SL</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>User Role</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in users" :key="index">
            <td>{{index +=1}}</td>
            <td>{{user.name}}</td>
            <td>{{ user.phone_number }}</td>
            <td>{{ user.email }}</td>
            <td class="font-primary" v-if="user.roles[0]">{{ user.roles[0].display_name }}</td>
            <td class="font-primary" v-else>NA</td>
            <td><router-link :to="{name:'editUser', params:{id: user.id}}"><i class="fas fa-edit text-success"></i></router-link></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style></style>
