<script setup>
  import axios from 'axios';
  import {ref, onMounted} from 'vue';

  const orderStatusesData = ref([]);
  const getOrderStatuses = async() => {
    try{
      const res = await axios.get('/admin/statuses');
      orderStatusesData.value = res.data.result.data
    }catch(error){
      console.log(error);
    }
  }

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
//     return date.toLocaleDateString('en-US', options);
//   }

  onMounted(() => {
    getOrderStatuses();
  });
</script>


<template>
  <div>
    <div class="table-responsive table-desi">
      <table class="table table-striped all-package">
        <thead>
          <tr>
            <th>SL</th>
            <th>Status Name</th>
            <th>Status Slug</th>
            <th>Status</th>
            <th>Payable Amount</th>
            <th>Option</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(order, index) in orderData" :key="index">
            <td>{{order.id}}</td>

            <td>{{  order.phone_number  }}</td>

            <td>{{  formatDate(order.created_at)  }}</td>

            <td>Cash on Delivery</td>

            <td class="order-success" v-if="is_paid">
              <span>Paid</span>
            </td>
            <td class="order-cancle" v-else>
              <span>Unpaid</span>
            </td>

            <td>{{  order.payable_price  }} tk</td>
            <td>
              <ul>
                <li>
                  <router-link :to="{name:'order_detail', params:{id: order.id}}"><span class="lnr lnr-eye text-danger"></span></router-link>
                </li>

                <li>
                  <router-link :to="{name:'order_tracking', params:{id: order.id}}"><i class="fas fa-edit text-success"></i></router-link>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<style>
</style>
