<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div>
            <div class="table-responsive table-desi">
              <table
                class="user-table invoice-table trans-table table table-striped"
              >
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Invoice</th>
                    <th>Date</th>
                    <th>Shipping</th>
                    <th>Amount</th>
                    <th>Tax</th>
                    <th>Total</th>
                    <th>Options</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>1</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>2</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>3</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>4</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>5</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>6</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>7</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>8</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>9</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>10</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>11</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>12</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>13</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>14</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>15</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>16</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>17</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>18</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>19</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>20</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>21</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>22</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>23</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>24</td>
                    <td>514984</td>
                    <td>01-05-2021</td>
                    <td>3.62</td>
                    <td>816</td>
                    <td>65.38</td>
                    <td>884.64</td>
                    <td>
                      <ul>
                        <li>
                          <router-link
                            to="/order_detail"
                            href="javascript:void(0)"
                          >
                            <span class="lnr lnr-eye"></span>
                          </router-link>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-pencil"></span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <span class="lnr lnr-trash"></span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- Pagination Box Start -->
        <div class="pagination-box">
          <nav class="ms-auto me-auto" aria-label="...">
            <ul class="pagination pagination-primary">
              <li class="page-item disabled">
                <a class="page-link" href="javascript:void(0)">Previous</a>
              </li>
              <li class="page-item active">
                <a class="page-link" href="javascript:void(0)">1</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">2</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">3</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">Next</a>
              </li>
            </ul>
          </nav>
        </div>
        <!-- Pagination Box End -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: "Rodela Crafts-Invoice",
};
</script>

<style></style>
