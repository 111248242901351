<script setup>
import {ref, onMounted} from 'vue'
import axios from 'axios';
import router from '@/router';
import { useRoute } from 'vue-router';

const {params}   = useRoute();
const name       = ref();
const charge     = ref();
const min_time   = ref();
const max_time   = ref();
const time_unite = ref('days');
const status     = ref('active');
const loading    = ref(false)
const errors     = ref();


const getDeliveryCharge = async() =>{
  loading.value = true;
  try {
    const res = await axios.get(`/admin/delivery-gateways/${params.id}`);
          name.value       = res.data.result.name;
          charge.value     = res.data.result.delivery_fee;
          min_time.value   = res.data.result.min_time;
          max_time.value   = res.data.result.max_time;
          time_unite.value = res.data.result.time_unit;
          status.value     = res.data.result.status;
  } catch (error) {
    console.log(error);
  }finally{
    loading.value = false;
  }
}



const submit = async() =>{
  loading.value = true;
  try {
      const res = await axios.put(`/admin/delivery-gateways/${params.id}`,{
          name : name.value,
          delivery_fee : charge.value,
          min_time : min_time.value,
          max_time : max_time.value,
          time_unit : time_unite.value,
          status : status.value,
      });
      if(res.status === 200){
        router.push({name: 'delivery_charge'});
      }else{
        errors.value = res.data.message;
      }
  } catch (error) {
      console.log(error);
  }finally{
    loading.value = false;
  }
}


onMounted(() => {
  getDeliveryCharge();
})
</script>
<template>
  <div class="tab-pane fade show active" id="pills-home" role="tabpanel">
    <form class="theme-form theme-form-2 mega-form" @submit.prevent="submit">
      <div class="card-header-1 d-flex justify-content-between mb-4">
        <h3><strong>Delivery Information</strong></h3>
        <router-link :to="{name:'delivery_charge'}" class="btn btn-info"><i class="fa fa-arrow-left"></i> Back</router-link>
      </div>
      {{ errors }}

      <div class="row">
        <div class="mb-4 row align-items-center">
          <label class="form-label-title col-lg-2 col-md-2 mb-0" >Delivery Area Name</label>
          <div class="col-md-4 col-lg-4">
            <input class="form-control" type="text" placeholder="Enter Delivery Area" v-model="name"/>
            <span v-if="errors">
              <span class="text-danger" v-for="(error, index) in errors.name" :key="index">{{error}}</span>
            </span>
          </div>

          <label class="col-lg-2 col-md-2 col-form-label form-label-title">Delivery Charge</label>
          <div class="col-md-4 col-lg-4">
              <input class="form-control" type="text" placeholder="Enter Delivery Charge" v-model="charge"/>
          </div>

          <label class="col-lg-2 col-md-2 mt-4 col-form-label form-label-title">Delivery Min-time</label>
          <div class="col-md-4 mt-4 col-lg-4">
              <input class="form-control" type="text" placeholder="Enter Delivery Minimum Time" v-model="min_time"/>
          </div>

          <label class="col-lg-2 col-md-2 mt-4 col-form-label form-label-title">Delivery Max-time</label>
          <div class="col-md-4 mt-4 col-lg-4">
              <input class="form-control" type="text" placeholder="Enter Delivery Maximum Time" v-model="max_time"/>
          </div>

          <label class="col-lg-2 col-md-2 col-form-label form-label-title">Delivery Time Unite</label>
          <div class="col-md-4 col-lg-4">
              <select name="" class="form-control" id="" v-model="time_unite">
                <option value="minutes">Minutes</option>
                <option value="hours">Hours</option>
                <option value="days">Days</option>
                <option value="weeks">Weeks</option>
              </select>
          </div>

          <label class="col-lg-2 mt-4 col-md-2 col-form-label form-label-title">Status</label>
          <div class="col-md-4  mt-4 col-lg-4">
              <select name="" id="" class="form-control" v-model="status">
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
          </div>
        </div>

        <div class="row mb-4">
            <button class="permission-btn" v-if="loading"><i class="fas fa-spinner fa-spin"></i> Loading...</button>
            <button class="permission-btn" v-else>Update Delivery Gateway</button>
        </div>
      </div>
    </form>
  </div>
</template>
<style lang="">
  
</style>