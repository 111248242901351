<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <!-- Table Start -->
        <div class="card-body">
          <div>
            <div class="table-responsive table-desi">
              <table class="user-table menu-list-table table table-striped">
                <thead>
                  <tr>
                    <th>
                      <span class="form-check user-checkbox">
                        <input
                          class="checkbox_animated"
                          type="checkbox"
                          value=""
                        />
                      </span>
                    </th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Created On</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <div class="form-check user-checkbox">
                        <input
                          class="checkbox_animated check-it"
                          type="checkbox"
                          value=""
                        />
                      </div>
                    </td>
                    <td>Product Menu</td>
                    <td class="menu-status">
                      <span class="warning">Restitute</span>
                    </td>
                    <td>2018-04-18T00:00:00</td>
                  </tr>

                  <tr>
                    <td>
                      <div class="form-check user-checkbox">
                        <input
                          class="checkbox_animated check-it"
                          type="checkbox"
                          value=""
                        />
                      </div>
                    </td>
                    <td>Category Menu</td>
                    <td class="menu-status">
                      <span class="success">Success</span>
                    </td>
                    <td>2018-04-18T00:00:00</td>
                  </tr>

                  <tr>
                    <td>
                      <div class="form-check user-checkbox">
                        <input
                          class="checkbox_animated check-it"
                          type="checkbox"
                          value=""
                        />
                      </div>
                    </td>
                    <td>Subcategory Menu</td>
                    <td class="menu-status">
                      <span class="success">Success</span>
                    </td>
                    <td>2018-04-18T00:00:00</td>
                  </tr>

                  <tr>
                    <td>
                      <div class="form-check user-checkbox">
                        <input
                          class="checkbox_animated check-it"
                          type="checkbox"
                          value=""
                        />
                      </div>
                    </td>
                    <td>Sales Menu</td>
                    <td class="menu-status">
                      <span class="warning">Restitute</span>
                    </td>
                    <td>2018-04-18T00:00:00</td>
                  </tr>

                  <tr>
                    <td>
                      <div class="form-check user-checkbox">
                        <input
                          class="checkbox_animated check-it"
                          type="checkbox"
                          value=""
                        />
                      </div>
                    </td>
                    <td>Vendor Menu</td>
                    <td class="menu-status">
                      <span class="success">Success</span>
                    </td>
                    <td>2018-04-18T00:00:00</td>
                  </tr>

                  <tr>
                    <td>
                      <div class="form-check user-checkbox">
                        <input
                          class="checkbox_animated check-it"
                          type="checkbox"
                          value=""
                        />
                      </div>
                    </td>
                    <td>Category Menu</td>
                    <td class="menu-status">
                      <span class="success">Success</span>
                    </td>
                    <td>2018-04-18T00:00:00</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Table End -->

        <!-- Pagination Box Start -->
        <div class="pagination-box">
          <nav class="ms-auto me-auto" aria-label="...">
            <ul class="pagination pagination-primary">
              <li class="page-item disabled">
                <a class="page-link" href="javascript:void(0)">Previous</a>
              </li>
              <li class="page-item active">
                <a class="page-link" href="javascript:void(0)">1</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">2</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">3</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">Next</a>
              </li>
            </ul>
          </nav>
        </div>
        <!-- Pagination Box End -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: "Rodela Crafts-Menu List",
};
</script>

<style></style>
