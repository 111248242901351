<template>
  <div class="container-fluid">
    <footer class="footer">
      <div class="row">
        <div class="col-md-12 footer-copyright text-center">
          <p class="mb-0">Copyright 2023 © Rodela Crafts Develop By <a href="https://servicekey.io/" target="_blank">ServiceKey</a></p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style>
.footer{
  position: absolute;
  right: 0;
}
</style>
