<script setup>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from 'axios';
import {ref} from 'vue';
import router from "@/router";
import { useRoute } from "vue-router";

const name       = ref('');
const image      = ref('');
const status     = ref('active');
const is_top     = ref('0');
const route      = useRoute();
const imgPreview = ref();
const errors     = ref({});
const loading    = ref(false);

const handleFileChange = (e) =>{
  image.value = e.target.files[0];
  let reader  = new FileReader();
  reader.addEventListener("load", function(){
    imgPreview.value = reader.result;
  }.bind(this), false);
  if(image.value){
    if(/\.(jpe?g|png|gif)$/i.test(image.value.name)){
      reader.readAsDataURL(image.value)
    }
  }
}

const submit = async () => {
  loading.value = true;
  try {
    const formdata = new FormData();
    formdata.append('name', name.value)
    formdata.append('image', image.value)
    formdata.append('status', status.value)
    formdata.append('is_top', is_top.value)

    const response = await axios.post("/admin/brands", formdata);
    if (response.data.success) {
      name.value = '';
      image.value = '';
      router.push({ name: 'brands' }); // Use router.push to navigate
    } else {
      errors.value = response.data.message;
    }
  } catch (error) {
    console.log(error);
  }finally{
    loading.value = false;
  }
}


</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="card-header-2 d-flex justify-content-between">
                <h5>New Brand Information</h5>
                <router-link :to="{name:'brands'}" class="btn btn-info"><i class="fa fa-arrow-left"></i> Back</router-link>
              </div>

              <form class="theme-form theme-form-2 mega-form" @submit.prevent="submit">
                <div class="row">
                  <div class="mb-4 row align-items-center">
                    <label class="form-label-title col-sm-2 mb-0" >Brand Name</label >
                    <div class="col-sm-10">
                      <input class="form-control" name="name" type="text" placeholder="Enter Your Brand Name" v-model="name"/>
                      <span v-if="errors.name" class="text-danger">{{ errors.name[0] }}</span>
                    </div>
                  </div>

                  <div class="mb-4 row align-items-center">
                    <label class="col-sm-2 col-form-label form-label-title" >Brand Status</label >
                    <div class="col-sm-4">
                      <select class="js-example-basic-single w-100" name="status" v-model="status" >
                        <option disabled>Select Brand Status</option>
                        <option value="active" selected>Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>

                    <label class="col-sm-2 col-form-label form-label-title" >Brand Status</label >
                    <div class="col-sm-4">
                      <select class="js-example-basic-single w-100" name="is_top" v-model="is_top" >
                        <option disabled>It's a top Brand ?</option>
                        <option value=1>Yes</option>
                        <option value=0>No</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="mb-4 row align-items-center">
                    <label class="col-sm-2 col-form-label form-label-title" >Image</label >
                    <div class="col-sm-10">
                      <input class="form-control form-choose" name="image" type="file" id="formFileMultiple" @change="handleFileChange" />
                      <img :src="imgPreview" width="100" alt="" class="mt-2">
                      <!-- <input class="form-control form-choose" name="image" type="file" id="formFileMultiple"/> -->
                      <span v-if="errors.image" class="text-danger">{{ errors.image[0] }}</span>
                    </div>
                  </div>
                  <button type="submit" class="brand-btn" v-if="loading"><i class="fas fa-spinner fa-spin"></i> Loading...</button>
                  <button type="submit" class="brand-btn" v-else>Add Brand</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
  .brand-btn{
    background-color: #06adb9;
    color: #fff;
    padding: 8px 25px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .brand-btn:hover{
    background-color: #038c96;
  }
  .theme-form select{
    border: none;
  }
</style>
