<script setup>

import axios from "axios";
import {ref, onMounted} from 'vue';

const rolse = ref([]);

const getUsers = async() =>{
  try {
    const res = await axios.get('/admin/roles');
    rolse.value = res.data.result.data
  } catch (error) {
    
  }
}

onMounted(() => {
  getUsers();
})

</script>



<template>
  <div>
    <div class="table-responsive table-desi">
      <table class="user-table table table-striped">
        <thead>
          <tr>
            <th>SL</th>
            <th>Name</th>
            <th>Display Name</th>
            <th>Description</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(role, index) in rolse" :key="index">
            <td>{{index +=1}}</td>
            <td>{{role.name}}</td>
            <td>{{ role.display_name }}</td>
            <td>{{ role.description }}</td>
            <td><router-link :to="{name:'editRole', params:{id: role.id}}"><i class="fas fa-edit text-success"></i></router-link></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style></style>
