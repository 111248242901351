<script setup>
import axios from 'axios';
import {ref, onMounted} from 'vue';
import router from "@/router";
import { useRoute } from "vue-router";
import Categories from '../category/categories.vue';

const name         = ref('');
const image        = ref('');
const status       = ref('active');
const category_id  = ref('');
const categories   = ref([]);
const is_top       = ref(0);
const route        = useRoute();
const imagePreview = ref();
const loading      = ref(false);

const handleFileChange = (e) =>{
  image.value = e.target.files[0];
  let reader  = new FileReader();
  reader.addEventListener("load", function(){
    imagePreview.value = reader.result;
  }.bind(this), false);
  if(image.value){
    if(/\.(jpe?g|png|gif)$/i.test(image.value.name)){
      reader.readAsDataURL(image.value)
    }
  }
}

const getCategories = async() =>{
  try {
    const res = await axios.get('/admin/categories');
    categories.value = res.data.result.data
  } catch (error) {
    console.log(error);
  }
}

const submit = async () => {
  loading.value = true;
  if(!name.value || !image.value){
      alert("Plase fill the filed");
  }else{
    
    try {
      const formdata = new FormData();
      formdata.append('name', name.value)
      formdata.append('image', image.value)
      formdata.append('category_id', category_id.value)
      formdata.append('status', status.value)
      formdata.append('is_top', is_top.value)

      const response = await axios.post("/admin/sub-categories", formdata);
      if(response.data.success){
        router.push({ name: 'sub_categories' }); // Use router.push to navigate
      }
    } catch (error) {
      console.log(error);
    }finally{
      loading.value = false;
    }
  }
}


onMounted(() => {
  getCategories();
})

</script>



<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="card-header-2 d-flex justify-content-between">
                <h5>New Sub Category Information</h5>
                <router-link :to="{name:'sub_categories'}" class="btn btn-info"><i class="fa fa-arrow-left"></i> Back</router-link>
              </div>

              <form class="theme-form theme-form-2 mega-form" @submit.prevent="submit">
                <div class="row">

                  <div class="mb-4 row align-items-center">

                    <label class="form-label-title col-sm-2 mb-0" >Sub Category Name</label >
                    <div class="col-sm-4">
                      <input class="form-control" type="text" placeholder="Enter Sub Category Name" v-model="name"/>
                    </div>

                    <label class="form-label-title col-sm-2 mb-0" >Category Id</label >
                    <div class="col-sm-4">
                      <select class="js-example-basic-single w-100" v-model="category_id" >
                        <option disabled>Select Category</option>
                        <option v-for="(category, index) in categories" :key="index" :value="category.id">{{category.name}}</option>
                      </select>
                    </div>

                  </div>

                  <div class="mb-4 row align-items-center">
                    <label class="col-sm-2 col-form-label form-label-title" >Sub Category Status</label >
                    <div class="col-sm-4">
                      <select class="js-example-basic-single w-100" v-model="status" >
                        <option disabled>Select Sub Category Status</option>
                        <option value="active" selected>Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>

                    <label class="col-sm-2 col-form-label form-label-title" >Is Top</label >
                    <div class="col-sm-4">
                      <select class="js-example-basic-single w-100" v-model="is_top" >
                        <option disabled>It's a top Sub Category ?</option>
                        <option value='1'>Yes</option>
                        <option value='0'>No</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="mb-4 row align-items-center">
                    <label class="col-sm-2 col-form-label form-label-title" >Image</label >
                    <div class="col-sm-10">
                      <input class="form-control form-choose" type="file" id="formFileMultiple" @change="handleFileChange" />
                      <img :src="imagePreview==null?image:imagePreview" width="100" alt="" class="mt-2">
                      <!-- <input class="form-control form-choose" name="image" type="file" id="formFileMultiple"/> -->
                    </div>
                  </div>
                  <button type="submit" class="category-btn" v-if="loading"><i class="fas fa-spinner fa-spin"></i> Loading...</button>
                  <button type="submit" class="category-btn" v-else>Add Sub Category</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
  .category-btn{
    background-color: #06adb9;
    color: #fff;
    padding: 8px 25px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .category-btn:hover{
    background-color: #038c96;
  }
  .theme-form select{
    border: none;
  }
</style>
