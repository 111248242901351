import { createRouter, createWebHistory } from "vue-router";
import layout1 from "../layouts/layout/layout1.vue";

import dashboard from "../pages/dashboard.vue";
import allUsers from "../components/general/users/allUsers/usersTable.vue";
import add_new_user from "../components/general/users/allUsers/add_user.vue";
import editUser from "../components/general/users/allUsers/edit_user.vue";

import permissions from "../components/general/users/allUsers/permission/permissionTable.vue";
import addPermissions from "../components/general/users/allUsers/permission/add_permission.vue";
import editPermissions from "../components/general/users/allUsers/permission/edit_permission.vue";

import roles from "../components/general/users/allUsers/userRoles/roles.vue";
import addRoles from "../components/general/users/allUsers/userRoles/add_role.vue";
import editRoles from "../components/general/users/allUsers/userRoles/edit_role.vue";

import order_list from "../components/application/orders/order_list/order_list_table.vue";
import add_order from "../components/application/orders/order_detail/add_order.vue";
import order_detail from "../components/application/orders/order_detail/order_detail_table.vue";
import edit_order from "../components/application/orders/order_detail/test_edit.vue";
import order_reports from "../components/application/orders/order_detail/order_reports.vue";

import shipped_pathao from "../components/application/orders/order_detail/shipped_pathao.vue";
import create_store from "../components/application/orders/order_detail/store_create.vue";

import shipped_streetfast from "../components/application/orders/order_detail/shipped_streetfast.vue";
import order_invoice from "../components/application/orders/order_detail/invoice.vue";
import multiple_invoice from "../components/application/orders/order_detail/multipleInvoice.vue";

import order_status from "../components/application/orders/order_status/order_status_list.vue";

import delivery_charge from "../components/application/orders/delivery_charge/delivery_charge.vue";
import add_delivery_charge from "../components/application/orders/delivery_charge/add_delivery_charge.vue";
import edit_delivery_charge from "../components/application/orders/delivery_charge/edit_delivery_charge.vue";

import payment_gateways from "../components/application/orders/payment_gateway/payment_gateways.vue";
import add_payment_gateway from "../components/application/orders/payment_gateway/add_payment_gateway.vue";
import edit_payment_gateway from "../components/application/orders/payment_gateway/edit_payment_gateway.vue";


import translation from "../pages/application/localization/translation.vue";
import currency_rate from "../pages/application/localization/currency_rate.vue";
import taxes from "../pages/application/localization/taxes.vue";

import brands from "../pages/application/brand/brands.vue";
import add_brand from "../pages/application/brand/add_new_brand.vue";
import edit_brand from "../pages/application/brand/edit_brand.vue";

import categories from "../pages/application/category/categories.vue";
import add_category from "../pages/application/category/add_new_category.vue";
import edit_category from "../pages/application/category/edit_categories.vue";

import sub_categories from "../pages/application/sub_category/sub_categories.vue";
import add_sub_category from "../pages/application/sub_category/add_sub_category.vue";
import edit_sub_category from "../pages/application/sub_category/edit_sub_category.vue";


import sizes from "../pages/application/product_size/sizes.vue";
import add_size from "../pages/application/product_size/add_new_size.vue";
import edit_size from "../pages/application/product_size/edit_size.vue";

import sliders from "../pages/application/slider/sliders.vue";
import add_slider from "../pages/application/slider/add_new_slider.vue";
import edit_slider from "../pages/application/slider/edit_slider.vue";

import products from "../pages/application/product/products.vue";
import add_product from "../pages/application/product/add_new_product.vue";
import edit_product from "../pages/application/product/edit_product.vue";

import menu_lists from "../pages/application/menus/menu_lists.vue";
import create_menu from "../pages/application/menus/create_menu.vue";
import coupon_list from "../pages/application/coupons/coupon_list.vue";
import create_coupon from "../pages/application/coupons/create_coupon.vue";
import product_review from "../pages/application/product_review.vue";
import invoice from "../pages/application/invoice.vue";
import profile_setting from "../pages/application/settings/profile_setting.vue";
import log_in from "../pages/application/log_in.vue";
import forgot_password from "../pages/application/forgot_password.vue";
import register from "../pages/application/register.vue";

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: dashboard,
    meta: { layout: layout1, breadcrumb: { type: 0 } },
  },
  {
    path: "/users",
    name: "allUsers",
    component: allUsers,
    meta: {
      layout: layout1,
      breadcrumb: { type: 1, title: "All Users", buttonPath: "/add_new_user" },
    },
  },
  {
    path: "/permission",
    name: "Permission",
    component: permissions,
    meta: {
      layout: layout1,
      breadcrumb: { type: 1, title: "All Permissions", buttonPath: "/add_permission" },
    },
  },
  {
    path: "/add_permission",
    name: "add_permission",
    component: addPermissions,
    meta: {
      layout: layout1,
      breadcrumb: { type: 2, title: "Add Permission"},
    },
  },
  {
    path: "/edit_permission/:id",
    name: "editPermission",
    component: editPermissions,
    meta: {
      layout: layout1,
      breadcrumb: { type: 2, title: "Edit Permission"},
    },
  },
  {
    path: "/roles",
    name: "roles",
    component: roles,
    meta: {
      layout: layout1,
      breadcrumb: { type: 1, title: "All Roles", buttonPath: "/add_role" },
    },
  },
  {
    path: "/add_role",
    name: "add_role",
    component: addRoles,
    meta: {
      layout: layout1,
      breadcrumb: { type: 2, title: "Add Role" },
    },
  },
  {
    path: "/edit_roles/:id",
    name: "editRole",
    component: editRoles,
    meta: {
      layout: layout1,
      breadcrumb: { type: 2, title: "Edit Role" },
    },
  },
  {
    path: "/add_new_user",
    name: "add_new_user",
    component: add_new_user,
    meta: {
      layout: layout1,
      breadcrumb: { type: 2, title: "Add New User" },
    },
  },
  {
    path: "/edit_user/:id",
    name: "editUser",
    component: editUser,
    meta: {
      layout: layout1,
      breadcrumb: { type: 2, title: "Add New User" },
    },
  },
  {
    path: "/order_list",
    name: "order_list",
    component: order_list,
    meta: { layout: layout1, breadcrumb: { type: 1, title: "Order List", buttonPath: "/add_order" } },
  },
  {
    path: "/add_order",
    name: "add_order",
    component: add_order,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Add New Order" } },
  },
  {
    path: "/order_detail/:id",
    name: "order_detail",
    component: order_detail,
    meta: {
      layout: layout1,
      breadcrumb: {
        type: 2,
        title: "Order Details",},
    },
  },
  {
    path: "/edit_order/:id",
    name: "edit_order",
    component: edit_order,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Order Edit" } },
  },
  {
    path: "/order_reports",
    name: "order_reports",
    component: order_reports,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Order Reports" } },
  },
  {
    path: "/shipped_pathao/:id",
    name: "shipped_pathao",
    component: shipped_pathao,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Order Shipped Pathao" } },
  },
  {
    path: "/create_store/:id",
    name: "create_store",
    component: create_store,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Create a new Store" } },
  },
  {
    path: "/shipped_streetfast/:id",
    name: "shipped_streetfast",
    component: shipped_streetfast,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Order Shipped Streetfast" } },
  },
  {
    path: "/order_invoice/:id",
    name: "order_invoice",
    component: order_invoice,
    meta: { layout: layout1, breadcrumb: { type: 2 } },
  },
  {
    path: "/multiple_invoice",
    name: "multiple_invoice",
    component: multiple_invoice,
    meta: { layout: layout1, breadcrumb: { type: 2} },
  },
  {
    path: "/order_status",
    name: "order_status",
    component: order_status,
    meta: {
      layout: layout1,
      breadcrumb: { type: 1, title: "Order Statuses", buttonPath: "/add_status" },
    },
  },
  {
    path: "/delivery_charge",
    name: "delivery_charge",
    component: delivery_charge,
    meta: {
      layout: layout1,
      breadcrumb: { type: 1, title: "Delivery Gateway", buttonPath: "/add_delivery_charge" },
    },
  },
  {
    path: "/add_delivery_charge",
    name: "add_delivery_charge",
    component: add_delivery_charge,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Delivery Gateway" } },
  },
  {
    path: "/edit_delivery_charge/:id",
    name: "edit_delivery_charge",
    component: edit_delivery_charge,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Edit Delivery Gateway" } },
  },
  {
    path: "/payment_gateway",
    name: "payment_gateway",
    component: payment_gateways,
    meta: {
      layout: layout1,
      breadcrumb: { type: 1, title: "Payment Gateway", buttonPath: "/add_payment_gateway" },
    },
  },
  {
    path: "/add_payment_gateway",
    name: "add_payment_gateway",
    component: add_payment_gateway,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Payment Gateway" } },
  },
  {
    path: "/edit_payment_gateway/:id",
    name: "edit_payment_gateway",
    component: edit_payment_gateway,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Edit Payment Gateway" } },
  },
  {
    path: "/translation",
    name: "translation",
    component: translation,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Translation" } },
  },
  {
    path: "/currency_rate",
    name: "currency_rate",
    component: currency_rate,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Currency Rates" } },
  },
  {
    path: "/taxes",
    name: "taxes",
    component: taxes,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Taxes" } },
  },
  {
    path: "/brands",
    name: "brands",
    component: brands,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Brands" } },
  },
  {
    path: "/add_brand",
    name: "add_brand",
    component: add_brand,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Add Brand" } },
  },
  {
    path: "/edit_brand/:id",
    name: "update.brand",
    component: edit_brand,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Edit Brand" } },
  },
  {
    path: "/categories",
    name: "categories",
    component: categories,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Categories" } },
  },
  {
    path: "/add_category",
    name: "add_category",
    component: add_category,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Add Category" } },
  },
  {
    path: "/edit_category/:id",
    name: "update.category",
    component: edit_category,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Edit Category" } },
  },

  {
    path: "/sub_categories",
    name: "sub_categories",
    component: sub_categories,
    meta: {
      layout: layout1,
      breadcrumb: { type: 1, title: "Sub Categories", buttonPath: "/add_sub_category" },
    },
  },
  {
    path: "/add_sub_category",
    name: "add_sub_category",
    component: add_sub_category,
    meta: {
      layout: layout1,
      breadcrumb: { type: 2, title: "Add Sub Category"},
    },
  },
  {
    path: "/edit_sub_category/:id",
    name: "edit_sub_category",
    component: edit_sub_category,
    meta: {
      layout: layout1,
      breadcrumb: { type: 2, title: "Edit Sub Category"},
    },
  },


  {
    path: "/products_sizes",
    name: "products_sizes",
    component: sizes,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Product Sizes" } },
  },
  {
    path: "/add_size",
    name: "add_size",
    component: add_size,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Add Size" } },
  },
  {
    path: "/edit_size/:id",
    name: "update.size",
    component: edit_size,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Edit Size" } },
  },
  {
    path: "/products",
    name: "products",
    component: products,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Products" } },
  },
  {
    path: "/add_product",
    name: "add_product",
    component: add_product,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Add Product" } },
  },
  {
    path: "/edit_product/:id",
    name: "update.product",
    component: edit_product,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Edit Product" } },
  },


  {
    path: "/sliders",
    name: "sliders",
    component: sliders,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Sliders" } },
  },
  {
    path: "/add_slider",
    name: "add_slider",
    component: add_slider,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Add Slider" } },
  },
  {
    path: "/edit_slider/:id",
    name: "update.slider",
    component: edit_slider,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Edit Slider" } },
  },



  {
    path: "/menu_lists",
    name: "menu_lists",
    component: menu_lists,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Menu Lists" } },
  },
  {
    path: "/create_menu",
    name: "create_menu",
    component: create_menu,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Create Menu" } },
  },
  {
    path: "/coupon_list",
    name: "coupon_list",
    component: coupon_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Coupon List" } },
  },
  {
    path: "/create_coupon",
    name: "create_coupon",
    component: create_coupon,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Create Coupon" } },
  },
  {
    path: "/product_review",
    name: "product_review",
    component: product_review,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Product Review" } },
  },
  {
    path: "/invoice",
    name: "invoice",
    component: invoice,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Invoice" } },
  },
  {
    path: "/profile_setting",
    name: "profile_setting",
    component: profile_setting,
    meta: {
      layout: layout1,
      breadcrumb: { type: 2, title: "Profile Setting" },
    },
  },
  {
    path: "/log_in",
    name: "log_in",
    component: log_in,
  },
  {
    path: "/forgot_password",
    name: "forgot_password",
    component: forgot_password,
  },
  {
    path: "/register",
    name: "register",
    component: register,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
