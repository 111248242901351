
<script setup>
import axios from "axios";
import {ref, onMounted} from 'vue';
import { Bootstrap5Pagination } from 'laravel-vue-pagination';

const permissionData = ref([]);

const getPermission = async(page = 1) =>{
  try {
    const res = await axios.get(`/admin/permissions?page=${page}`);
    permissionData.value = res.data.result
  } catch (error) {
    console.log(error);
  }
}

onMounted(() => {
  getPermission();
})
</script>


<template>
  <div>
    <div class="table-responsive table-desi">
      <table class="user-table table table-striped">
        <thead>
          <tr>
            <th>SL</th>
            <th>Display Name</th>
            <th>Name</th>
            <th>Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(permission, index) in permissionData.data" :key="index">
            <td>{{ index += 1 }}</td>
            <td>{{ permission.display_name }}</td>
            <td>{{ permission.name }}</td>
            <td>{{ permission.description }}</td>
            <td>
              <span class="disable-permission">
                <router-link :to="{name:'editPermission', params:{id: permission.id}}" disabled>
                  <i class="fas fa-edit text-success"></i>
                </router-link>
              </span>
            </td>
          </tr>
        </tbody>
          <div class="pagination-box">
              <nav class="ms-auto me-auto" aria-label="...">
                <ul class="pagination pagination-primary">
                  <Bootstrap5Pagination
                    :data="permissionData"
                    @pagination-change-page="getPermission"
                  />
                </ul>
              </nav>
          </div>
        </table>
      </div>
      </div>
</template>



<style>
  .disable-permission{
    position:relative;
  }
  .disable-permission::before{
    content: '';
    position: absolute;
    width: 140%;
    height: 140%;
    background: rgba(0, 0, 0, 0.37);
    top: -5px;
    left: -5px;
    border-radius: 5px;
  }
</style>
