<script setup>
import {ref, onMounted} from 'vue'
import axios from 'axios';

const delivery_gateways = ref([]);
const getDeliveryCharge = async() =>{
  try {
    const res = await axios.get('/admin/delivery-gateways');
     delivery_gateways.value = res.data.result.data
  } catch (error) {
    console.log(error);
  }
}

onMounted(() => {
  getDeliveryCharge();
})
</script>
<template>
  <div>
    <div class="table-responsive table-desi">
      <table class="user-table table table-striped">
        <thead>
          <tr>
            <th>SL</th>
            <th>Delivery Area Name</th>
            <th>Delivery Charge</th>
            <th>Delivery Time</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(delivery, index) in delivery_gateways" :key="index">
            <td>{{index +=1}}</td>
            <td>{{delivery.name}}</td>
            <td>{{ delivery.delivery_fee }} tk</td>
            <td>{{ delivery.min_time }} {{delivery.time_unit}} - {{ delivery.max_time }} {{delivery.time_unit}}</td>
            <td>{{delivery.status=='active'? "Active" : "Inactive"}}</td>
            <td><router-link :to="{name:'edit_delivery_charge', params:{id: delivery.id}}"><i class="fas fa-edit text-success"></i></router-link></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style lang="">
  
</style>