import axios from "axios";

const state = {
  content: [],
  contentToShow: 10,
  auth_token: '',
  auth_status: false,
  auth_info: {
    name: '',
    email: '',
    phone_number: ''
  }
};

// getters
const getters = {};

// mutations
const mutations = {
  setContentToShow(state, payload) {
    state.content = [];
    state.content = payload.allData.slice(
      payload.currentPage * state.contentToShow - state.contentToShow,
      payload.currentPage * state.contentToShow
    );
  },

  // Set auth token when user login
  SET_AUTH_TOKEN(state, payload) {
    state.auth_token = payload;
    state.auth_status = true;
  },

  // Set auth user info when user login
  SET_AUTH_INFO(state, payload) {
    state.auth_info.name = payload.name;
    state.auth_info.email = payload.email;
    state.auth_info.phone_number = payload.phone_number;
  },

  logoutUser(state) {
    state.user = false;
    localStorage.removeItem("user");
  },
};

// actions
const actions = {

  changePage({ commit }, payload) {
    payload.toPage <= payload.totalPages &&
      payload.toPage > 0 &&
      (payload["currentPage"] = payload.toPage);
    payload.toPage <= payload.totalPages
      ? (payload = payload)
      : (payload["toPage"] = payload.currentPage);
    commit("setContentToShow", payload);
    return [...calculatePages(payload)];
  },

  // setUser({ commit }, payload) {
  //   commit("setUser", payload);
  // },

  LOGIN(context, payload) {
    return new Promise((resolve, reject) => {
        axios.post('/admin/login', payload)
        .then(res => {
          if (res.data.success) {
            // set data in vuex
            context.commit('SET_AUTH_TOKEN', res.data.result.token)
            context.commit('SET_AUTH_INFO', res.data.result.user)

            // Set data in local storage
            localStorage.setItem('auth_token', res.data.result.token)
            localStorage.setItem('auth_status', true)
            localStorage.setItem('auth_name', res.data.result.user.name)
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    })
  },

  LOGOUT(context) {
    let token = localStorage.getItem('auth_token');
    axios.defaults.headers.common['Authorization'] = 'Bearer '+ token;

      return new Promise((resolve, reject) => {
          axios.post('/admin/logout')
          .then(res => {
            if (res.data.success) {
              // context.commit('SET_AUTH_LOGOUT')
              localStorage.removeItem('auth_token');
              localStorage.setItem('auth_status', false)
              localStorage.removeItem('auth_name');
            }
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      })
    }
};

function calculatePages(payload) {
  var pages = [];
  let startpage =
    payload.currentPage === 1 ? payload.currentPage : payload.currentPage - 1;
  let endPage =
    payload.currentPage === 1
      ? payload.currentPage + 2
      : payload.currentPage + 1 <= payload.totalPages
      ? payload.currentPage + 1
      : payload.currentPage;
  for (let i = startpage; i <= endPage; i++) {
    i <= payload.totalPages && pages.push(i);
  }
  return pages;
}
export default { namespaced: true, state, getters, actions, mutations };
