
<script setup>
import 'select2/dist/css/select2.css';
import 'select2/dist/js/select2';
import axios from 'axios';
import {ref, onMounted} from 'vue'
import router from '@/router';
import { useRoute } from 'vue-router';

const {params}          = useRoute();

const cities            = ref([]);
const zones             = ref([]);
const areas             = ref([]);
const cityID            = ref();
const zoneID            = ref();
const areaID            = ref();
const storeName         = ref();
const contactName       = ref();
const contactNmuber     = ref();
const contactAdress     = ref();
const secondaryContact  = ref();
const loadingZone       = ref(false);
const loadingArea       = ref(false);
const loading           = ref(false);
const errors            = ref({
    name : '',
    contact_name : '',
    contact_number : '',
    address : '',
    city_id : '',
    zone_id : '',
    area_id : '',
    message : '',
});

const getCities = async() =>{
    try {
        const res = await axios.get('/admin/pathao/cities');
        cities.value = res.data.result.data.data
    } catch (error) {
        console.log(error);
    }
}

const getZones = async(cityID) =>{
    zones.value = [];
    areas.value = [];
    loadingZone.value = true;
    loadingArea.value = true;
    try {
        const res = await axios.get(`/admin/pathao/zones/${cityID}`);
        zones.value = res.data.result.data.data
    } catch (error) {
        console.log(error);
    }
    finally{
        loadingZone.value = false;
        loadingArea.value = false;
    }
}

const getAreas = async(zoneID) =>{
    areas.value = [];
    loadingArea.value = true;
    try {
        const res = await axios.get(`/admin/pathao/areas/${zoneID}`);
        areas.value = res.data.result.data.data
    } catch (error) {
        console.log(error);
    }
    finally{
        loadingArea.value = false;
    }
}

const submit = async() =>{
    loading.value = true;
    try {
        const res = await axios.post('/admin/pathao/stores', {
            name            : storeName.value,
            contact_name    : contactName.value,
            contact_number  : contactNmuber.value,
            secondary_contact : secondaryContact.value,
            city_id         : cityID.value,
            zone_id         : zoneID.value,
            area_id         : areaID.value,
            address         : contactAdress.value
        });
        if(res.status==200){
            alert('Your Store is Successfully Created');
            router.push({ name: 'shipped_pathao', params:{id : params.id} });
        }else{
            errors.value.name = res.data.message.name? res.data.message.name[0] : '';
            errors.value.contact_name = res.data.message.contact_name? res.data.message.contact_name[0] : '';
            errors.value.contact_number = res.data.message.contact_number? res.data.message.contact_number[0] : '';
            errors.value.address = res.data.message.address? res.data.message.address[0] : '';
            errors.value.city_id = res.data.message.city_id? res.data.message.city_id[0] : '';
            errors.value.zone_id = res.data.message.zone_id? res.data.message.zone_id[0] : '';
            errors.value.area_id = res.data.message.area_id? res.data.message.area_id[0] : '';
            errors.value.message = typeof(res.data.message) !== 'object'? res.data.message : '';
        }
    } catch (error) {
        console.log(error);
    }
    finally{
        loading.value = false
    }
}


onMounted(() => {
    getCities();
    
})
</script>



<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="bg-inner cart-section order-details-table">
            <div class="row">
                <div class="col-md-12">
                    <div class="card-header-2 d-flex justify-content-between">
                        <h5>Store Information</h5>
                        <span>
                            <router-link :to="{name:'shipped_pathao', params:{id:params.id}}" class="btn btn-info"><i class="fa fa-arrow-left"></i> Back</router-link>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-12">
                    <form @submit.prevent="submit">
                        <div class="row">
                            <div class="col-md-6 mb-4">
                                <label for="">Store Name<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Store Name ..." v-model="storeName">
                                <span class="text-danger" v-if="errors.name">{{errors.name}}</span>
                            </div>
                            <div class="col-md-6 mb-4">
                                <label for="">Contact Name<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Contact Name ..." v-model="contactName">
                                <span class="text-danger" v-if="errors.contact_name">{{errors.contact_name}}</span>
                            </div>
                            <div class="col-md-6 mb-4">
                                <label for="">Contact Number<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Contact Number ..." v-model="contactNmuber">
                                <span class="text-danger" v-if="errors.contact_number">{{errors.contact_number}}</span>
                            </div>
                            <div class="col-md-6 mb-4">
                                <label for="">Secondary Contact (Optional)</label>
                                <input type="text" class="form-control" placeholder="Enter Contact Number ..." v-model="secondaryContact">
                            </div>
                            <div class="col-md-6 mb-4">
                                <label for="">City <span class="text-danger">*</span></label>
                                <select v-model="cityID" class="form-control" @change="getZones(cityID)">
                                    <option v-for="(city, index) in cities" :key="index" :value="city.city_id">{{ city.city_name }}</option>
                                </select>
                                <span class="text-danger" v-if="errors.city_id">{{errors.city_id}}</span>
                            </div>
                            <div class="col-md-6 mb-4">
                                <label for="">Zone <span class="text-danger">*</span></label>
                                <select class="form-control" v-if="loadingZone">
                                    <option disabled selected>Loading . . .</option>
                                </select>
                                <select v-model="zoneID" class="form-control" @change="getAreas(zoneID)" v-else>
                                    <option v-for="(zone, index) in zones" :key="index" :value="zone.zone_id">{{ zone.zone_name }}</option>
                                </select>
                                <span class="text-danger" v-if="errors.zone_id">{{errors.zone_id}}</span>
                            </div>
                            <div class="col-md-6 mb-4">
                                <label for="">Recipient Area<span class="text-danger">*</span></label>
                                <select class="form-control" v-if="loadingArea">
                                    <option disabled selected>Loading . . .</option>
                                </select>
                                <select class="form-control" v-model="areaID" v-else>
                                    <option v-for="(area, index) in areas" :key="index" :value="area.area_id">{{ area.area_name }}</option>
                                </select>
                                <span class="text-danger" v-if="errors.area_id">{{errors.area_id}}</span>
                            </div>
                            <div class="col-md-6 mb-4">
                                <label for="">Address</label>
                                <input type="text" class="form-control" placeholder="Enter Contact Address ..." v-model="contactAdress">
                                <span class="text-danger" v-if="errors.address">{{errors.address}}</span>
                            </div>
                            
                            <div class="row mb-4 text-end">
                                <span class="text-danger" v-if="errors.message">{{errors.message}}</span>
        
                                <span v-if="loading"><button class="btn btn-danger" ><i class="fas fa-spinner fa-spin"></i> Loading...</button></span>
                                <span v-else><button class="btn btn-danger">Store Create</button></span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
          </div>
          <!-- section end -->
        </div>
      </div>
    </div>
  </div>
</template>

<style>
    .box-shadow{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 20px;
        padding: 40px 30px;
    }
    .select2 span{
        width: 100%;
    }
</style>
