
<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import router from '@/router';
import { useRoute } from 'vue-router';

const { params } = useRoute();
const order_details = ref('');
const payment_gateway = ref('');
const order_Items = ref([]);
const statuses = ref([]);

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return date.toLocaleDateString('en-US', options);
}

const getOrder = async () => {
  try {
    const res = await axios.get(`/admin/orders/${params.id}`);
    order_details.value = res.data.result
    payment_gateway.value = res.data.result.payment_gateway
    order_Items.value = res.data.result.items
  } catch (error) {
    console.log(error);
  }
}

const getStatuses = async() =>{
  try {
    const res = await axios.get('/admin/statuses');
    statuses.value = res.data.result.data
  } catch (error) {
    console.log(error);
  }
}


onMounted(() => {
  getOrder();
  getStatuses();
})
</script>



<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="bg-inner cart-section order-details-table">
            <div class="row">
              <div class="col-12 overflow-visible">
                <ol class="progtrckr">
                  <li
                    :class="[
                      { 'progtrckr-todo': status.status === 'active' },
                      { 'progtrckr-done': status.id<=order_details.status_id},
                    ]"
                    v-for="(status, index) in statuses" :key="index"
                    :hidden="status.id==5 && order_details.status_id!==5"
                  >
                    <h5>{{ status.name }}</h5>
                    <h6 v-if="status.id===order_details.status_id">{{ formatDate(order_details.updated_at) }}</h6>
                    <h6 v-else-if="status.id<order_details.status_id">Complete</h6>
                    <h6 v-else>Pending</h6>
                  </li>
                </ol>
              </div>
            </div>
            <div class="row g-4">
              <div class="col-xl-8">
                <div class="table-responsive table-details">
                  <table class="table cart-table table-borderless">
                    <thead>
                      <tr>
                        <th colspan="2">orders</th>
                        <th class="text-end" colspan="2">
                          <router-link :to="{name:'edit_order', params:{id: order_details.id}}" class="theme-color">Edit order</router-link>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr class="table-order" v-for="(item, index) in order_Items" :key="index">
                        <td>
                          <a href="javascript:void(0)">
                            <img :src="item.image" class="img-fluid blur-up lazyload" alt="" />
                          </a>
                        </td>
                        <td>
                          <p>Product Name</p>
                          <h5>{{ item.name }}</h5>
                        </td>
                        <td>
                          <p>Quantity</p>
                          <h5>{{ item.pivot.quantity }}</h5>
                        </td>
                        <td>
                          <p>Unit Price</p>
                          <h5>
                            <del v-if="item.pivot.item_sell_price" class="text-danger">{{ item.pivot.item_mrp }} tk </del>
                            <span class="text-success"> {{ item.pivot.item_sell_price ? item.pivot.item_sell_price : item.pivot.item_mrp }} tk</span>
                          </h5>
                        </td>
                      </tr>
                    </tbody>

                      <tfoot>
                        <tr class="table-order">
                          <td colspan="3">
                            <h5>Subtotal :</h5>
                          </td>
                          <td>
                            <h4>{{ order_details.sell_price }} tk</h4>
                          </td>
                        </tr>

                        <tr class="table-order">
                          <td colspan="3">
                            <h5>Shipping :</h5>
                          </td>
                          <td>
                            <h4>{{ order_details.delivery_charge }} tk</h4>
                          </td>
                        </tr>

                        <!-- <tr class="table-order">
                          <td colspan="3">
                            <h5>Tax(GST) :</h5>
                          </td>
                          <td>
                            <h4>${{ orders.tableFooter.tax }}</h4>
                          </td>
                        </tr> -->

                        <tr class="table-order">
                          <td colspan="3">
                            <h4 class="theme-color fw-bold">Total Price :</h4>
                          </td>
                          <td>
                            <h4 class="theme-color fw-bold">
                              {{ order_details.payable_price }} tk
                            </h4>
                          </td>
                        </tr>
                      </tfoot>
                  </table>
                </div>
              </div>

              <div class="col-xl-4">
                <div class="order-success">
                  <div class="row g-4">

                    <h4>summery</h4>
                    <ul class="order-details">
                      <li>Order ID: {{  order_details.id  }}</li>
                      <li>Order ID: <span class="text-success">{{  order_details.phone_number  }}</span></li>
                      <li>Order Date: {{  formatDate(order_details.created_at)  }}</li>
                      <li>Order Total: <span class="text-dark">{{  order_details.payable_price  }} <span class="text-success">tk</span></span></li>
                    </ul>
    
                    <h4>shipping address</h4>
                    <ul class="order-details">
                      <li>{{  order_details.address_details  }}</li>
                      <li>{{  order_details.district  }}</li>
                      <li> Bangladesh </li>
                    </ul>
    
                    <div class="payment-mode">
                      <h4>payment method</h4>
                      <p>{{  payment_gateway.name }}</p>
                    </div>
    
                    <div class="delivery-sec">
                      <h3>
                        expected date of delivery:
                        <span> 2 days latter </span>
                      </h3>
                      <router-link to="/order_tracking" href="javascript:void(0)">track order</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- section end -->
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .progtrckr li.progtrckr-done::before{
    font-size: 16px !important;
  }
</style>
