import router from "@/router";
import axios from "axios";
import { defineStore } from "pinia";


export const useCart = defineStore("cart", {
    state: () =>({
        cartItems   : [],
        orderDetails: '',
        loading     : false,
        loading2    : false,
        items       : []
    }),
    // persist: {
    //     paths: ['cartItems'],
    // },

    getters: {
        totalPrice: (state) =>{
            let total = 0;
            state.cartItems.map((el) =>{
                total += el["price"] * el["quantity"]
            })
            return total;
        },
        cartItemsCount: (state) =>{
            let total = 0;
            state.cartItems.map((el) =>{
                total += el["quantity"]
            })
            return total;
        },
    },

    actions: {
        async getData(id){
            this.cartItems = [];
            try {
                // this.cartItems.splice(0, this.cartItems.length);
                const res = await axios.get(`/admin/orders/${id}`);
                this.orderDetails = res.data.result
                let items = res.data.result.items;
                for(let item of items){
                    let orderItem = {
                        item_id       : item.id,
                        name          : item.name,
                        buy_price     : item.pivot.item_buy_price,
                        sell_price    : item.pivot.item_sell_price,
                        price         : item.pivot.item_sell_price,
                        mrp           : item.pivot.item_mrp,
                        discount      : item.pivot.item_discount,
                        image         : item.image,
                        size_id       : item.pivot.size_id,
                        quantity      : item.pivot.quantity,
                        product_prices: item.product_prices,
                        pivot         : item.pivot,
                    }
                    
                    this.cartItems.push(orderItem);

                    
                }
            } catch (error) {
                console.log(error);
            } finally {
                // isPreloader.value = false
            }
        },

        async updateSizeItem(index, updatedItem) {
            if (index >= 0 && index < this.cartItems.length) {
                // Check if the provided index is valid
                this.cartItems[index] = updatedItem;
            }
        },
        async updateCartItemQuantity(index, updatedItem) {
            if (index >= 0 && index < this.cartItems.length) {
                // Check if the provided index is valid
                this.cartItems[index] = updatedItem;
            }
        },

        async addOrder(orderInfo){
            this.loading = true;
            try {
                const response = await axios.post('/admin/orders',{
                    customer_name      : orderInfo.customer_name,
                    phone_number       : orderInfo.phone_number,
                    address_details    : orderInfo.address_details,
                    items              : this.cartItems,
                    delivery_gateway_id: orderInfo.dg_id,
                    payment_gateway_id : 1,
                    status_id          : orderInfo.status_id,
                    is_paid            : orderInfo.is_paid,
                    district           : orderInfo.district,
                    special_discount   : orderInfo.special_discount,
                    delivery_charge    : orderInfo.delivery_charge,
                });
                if(response.status == 200){
                    router.push({name:'order_list'})
                }

            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false
            }
        },

        async updateOrder(orderId, orderInfo){
            this.loading = true;
            this.items = []
            for(let item of this.cartItems){
                let processItem = {
                    'item_id'   : item.item_id,
                    'buy_price' : item.buy_price,
                    'sell_price': item.sell_price,
                    'price'     : item.price,
                    'mrp'       : item.mrp,
                    'discount'  : item.discount,
                    'size_id'   : item.size_id,
                    'quantity'  : item.quantity,
                }

                this.items.push(processItem)
            }

            try {
                const response = await axios.put(`/admin/orders/${orderId}`,{
                    customer_name: orderInfo.customer_name,
                    phone_number       : orderInfo.phone_number,
                    address_details    : orderInfo.address_details,
                    delivery_gateway_id: orderInfo.dg_id,
                    payment_gateway_id : 1,
                    status_id          : orderInfo.status_id,
                    is_paid            : orderInfo.is_paid,
                    district           : orderInfo.district,
                    special_discount   : orderInfo.special_discount,
                    delivery_charge    : orderInfo.delivery_charge,
                    items              : this.items,
                });
                if(response.status == 200){
                    router.push({name:'order_list'})
                }

            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false
            }
        },




        async addToCart(product){
            let item = product;
            this.loading2 = true;

            if(this.cartItems.length > 0){
                if(item.size_id){
                    let boolean = this.cartItems.some((i) => i.item_id === item.item_id && i.size_id === item.size_id);
                    if(boolean){
                        let index = this.cartItems.findIndex((i) => i.item_id === item.item_id && i.size_id === item.size_id);
                        this.cartItems[index]["quantity"] += item.quantity;
                    }else{
                        this.cartItems.push(item)                    
                    }
                }else{
                    let boolean = this.cartItems.some((i) => i.item_id === item.item_id)
                    if(boolean){
                        let index = this.cartItems.findIndex((i) => i.item_id === item.item_id);
                        this.cartItems[index]["quantity"] += item.quantity;
                    }else{
                        this.cartItems.push(item)                    
                    }
                }    
            }else{
                this.cartItems.push(item)
            }
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.loading2 = false;
        },

        async destroy(index){
            this.cartItems.splice(index, 1);
        },
        async removeAllItems() {
            // Assuming this.cartItems is your array
            this.cartItems.splice(0, this.cartItems.length);
        },
        async decrement(index){
            if(this.cartItems[index]["quantity"] !== 1){
                this.cartItems[index]["quantity"] -= 1;
            }
        },
        async increment(index){
            this.cartItems[index]["quantity"] += 1;
        }, 

    }
})