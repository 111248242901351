
<script setup>
import axios from "axios";
import {ref, onMounted} from 'vue'; 
import router from "@/router";
import { useRoute } from "vue-router";

const {params} = useRoute();
const DisplayName = ref('');
const description = ref('');

const getData = async() =>{
    try {
      const res = await axios.get(`/admin/permissions/${params.id}`);
      DisplayName.value = res.data.result.display_name
      description.value = res.data.result.description
    } catch (error) {
      
    }
}

const submit = async() =>{
    try {
        const response = await axios.put(`/admin/permissions/${params.id}`,{
            display_name : DisplayName.value,
            description : description.value,
        });
        router.push({ name: 'Permission' });
    } catch (error) {
        
    }
}

onMounted(() => {
  getData();
})

</script>




<template>
  <div class="tab-pane fade show active" id="pills-home" role="tabpanel">
    <form class="theme-form theme-form-2 mega-form" @submit.prevent="submit">
      <div class="card-header-1 d-flex justify-content-between mb-4">
        <h3><strong>Permission Information</strong></h3>
        <router-link :to="{name:'Permission'}" class="btn btn-info"><i class="fa fa-arrow-left"></i> Back</router-link>
      </div>

      <div class="row">
        <div class="mb-4 row align-items-center">
          <label class="form-label-title col-lg-2 col-md-3 mb-0" >Permission Name</label>
          <div class="col-md-9 col-lg-10">
            <input class="form-control" type="text" v-model="DisplayName"/>
          </div>
        </div>

        <div class="mb-4 row align-items-center">
          <label class="col-lg-2 col-md-3 col-form-label form-label-title">Permission Description</label>
          <div class="col-md-9 col-lg-10">
            <textarea class="form-control" name="" id="" cols="30" rows="3" v-model="description"></textarea>
          </div>
        </div>
        <div class="row mb-4">
            <button class="permission-btn">Update permission</button>
        </div>
      </div>
    </form>
  </div>
</template>

<style>
.permission-btn{
    background-color: #06adb9;
    color: #fff;
    padding: 8px 25px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .permission-btn:hover{
    background-color: #038c96;
  }
</style>
