<script setup>
import {ref, onMounted} from 'vue'
import axios from 'axios';

const payment_gateways = ref([]);
const getPaymentGateways = async() =>{
  try {
    const res = await axios.get('/admin/payment-gateways');
     payment_gateways.value = res.data.result.data
  } catch (error) {
    console.log(error);
  }
}

onMounted(() => {
  getPaymentGateways();
})
</script>
<template>
  <div>
    <div class="table-responsive table-desi">
      <table class="user-table table table-striped">
        <thead>
          <tr>
            <th>SL</th>
            <th>Payment Gateway Name</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(payment_gateway, index) in payment_gateways" :key="index">
            <td>{{index +=1}}</td>
            <td>{{payment_gateway.name}}</td>
            <td>{{payment_gateway.status=='active'? "Active" : "Inactive"}}</td>
            <td><router-link :to="{name:'edit_payment_gateway', params:{id: payment_gateway.id}}"><i class="fas fa-edit text-success"></i></router-link></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style lang="">
  
</style>