

<script setup>
  import { Bootstrap5Pagination } from 'laravel-vue-pagination';
  import axios from 'axios';
  import {ref, onMounted} from 'vue';

  const sizeData = ref([]);
  const getImages = async() => {
    try {
      const res = await axios.get('/admin/sizes',{
        params:{
          page:1,
        }
      });
      sizeData.value = res.data.result.data;
    } catch (error) {
    }
  }

  onMounted(() => {
    getImages();
  });
</script>
<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="row"><div class="col-md-12 d-flex justify-content-end"><router-link :to="{name:'add_size'}" class="btn btn-info mb-2" >Add New size</router-link></div></div>
          <div>
            <div class="table-responsive table-desi table-product">
              <table class="table table-bordered all-package">
                <thead>
                  <tr>
                    <th class="w-10">SL</th>
                    <th>Size Name</th>
                    <th>Size Status</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(size, index) in sizeData" :key="index">
                    <td>{{ index +=1 }}</td>

                    <td>
                      <a href="javascript:void(0)">{{ size.name }}</a>
                    </td>
                    <td class="td-cross" v-if="size.status=='inactive'">
                      <span class="lnr lnr-cross-circle"></span>
                    </td>
                    <td class="td-check" v-else>
                      <span class="lnr lnr-checkmark-circle"> </span>
                    </td>

                    <td>
                      <ul>

                        <li>
                          <router-link :to="{name:'update.size', params:{ id: size.id}}"> <i class="fas fa-edit text-success"></i> </router-link>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="pagination-box">
          <nav class="ms-auto me-auto" aria-label="...">
            <ul class="pagination pagination-primary">
              <li class="page-item disabled">
                <a class="page-link" href="javascript:void(0)" tabindex="-1"
                  >Previous</a
                >
              </li>

              <li class="page-item active">
                <a class="page-link" href="javascript:void(0)">1</a>
              </li>

              <li class="page-item">
                <a class="page-link" href="javascript:void(0)"
                  >2 <span class="sr-only">(current)</span>
                </a>
              </li>

              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">3</a>
              </li>

              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">Next</a>
              </li>
              <Bootstrap5Pagination
                  :data="sizeData"
                  @pagination-change-page="getImages"
              />
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>



<style>
  .border-none{
    border: none;
  }
</style>
