<script setup>
import {ref, onMounted} from 'vue'
import axios from 'axios';
import router from '@/router';
import { useRoute } from 'vue-router';

const {params} = useRoute();
const name     = ref('');
const status   = ref('active');
const loading  = ref(false)
const errors   = ref();

const getPaymentGateway = async() =>{
  try {
    const res = await axios.get(`/admin/payment-gateways/${params.id}`);
     name.value   = res.data.result.name
     status.value = res.data.result.status
  } catch (error) {
    console.log(error);
  }
}

const submit = async() =>{
  loading.value = true;
  try {
      const res = await axios.put(`/admin/payment-gateways/${params.id}`,{
          name  : name.value,
          status: status.value,
      });
      if(res.status === 200){
        router.push({name: 'payment_gateway'});
      }else{
        errors.value = res.data.message
      }
  } catch (error) {
      console.log(error);
  }finally{
    loading.value = false;
  }
}


onMounted(() => {
  getPaymentGateway();
})
</script>
<template>
  <div class="tab-pane fade show active" id="pills-home" role="tabpanel">
    <form class="theme-form theme-form-2 mega-form" @submit.prevent="submit">
      <div class="card-header-1 d-flex justify-content-between mb-4">
        <h3><strong>Payment Gateway Information</strong></h3>
        <router-link :to="{name:'payment_gateway'}" class="btn btn-info"><i class="fa fa-arrow-left"></i> Back</router-link>
      </div>

      <div class="row">
        <div class="mb-4 row align-items-center">
          <label class="form-label-title col-lg-2 col-md-2 mb-0" >Payment Gateway Name</label>
          <div class="col-md-4 col-lg-4">
            <input class="form-control" type="text" placeholder="Enter Payment Gateway Name" v-model="name"/>
            <span v-if="errors">
              <span class="text-danger" v-for="(error, index) in errors.name" :key="index">{{error}}</span>
            </span>
          </div>

          <label class="col-lg-2 col-md-2 col-form-label form-label-title">Status</label>
          <div class="col-md-4 col-lg-4">
              <select name="" id="" class="form-control" v-model="status">
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
          </div>
        </div>

        <div class="row mb-4">
            <button class="permission-btn" v-if="loading"><i class="fas fa-spinner fa-spin"></i> Loading...</button>
            <button class="permission-btn" v-else>Add Gateway</button>
        </div>
      </div>
    </form>
  </div>
</template>
<style lang="">
  
</style>