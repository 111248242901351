

<script setup>
  import axios from 'axios';
  import {ref, onMounted} from 'vue';
  import { Bootstrap5Pagination } from 'laravel-vue-pagination';

  const subCategoriesData = ref([]);
  const paginate_size     = ref(10);

  const getSubCategories = async(page = 1) => {
    try {
      const res = await axios.get(`/admin/sub-categories`,{
        params:{
          page:page,
        }
      });
      subCategoriesData.value = res.data.result;
    } catch (error) {
    }
  }

  onMounted(() => {
    getSubCategories();
  });
</script>
<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div>
            <div class="table-responsive table-desi table-product">
              <table class="table table-bordered all-package">
                <thead>
                  <tr>
                    <th class="w-10">SL</th>
                    <th>Sub Category Image</th>
                    <th>Sub Category Name</th>
                    <th>Category Name</th>
                    <th>Sub Category Position</th>
                    <th>Sub Category Status</th>
                    <th>Action  </th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(subCategory, index) in subCategoriesData.data" :key="index">
                    <td>{{ index +=1 }}</td>
                    <td>
                      <img
                        :src="subCategory.image"
                        class="img-fluid"
                        alt=""
                        width="50"
                      />
                    </td>

                    <td>
                      <a href="javascript:void(0)">{{ subCategory.name }}</a>
                    </td>
                    <td>
                      <a href="javascript:void(0)">{{ subCategory.category_id }}</a>
                    </td>

                    <td>
                      <a href="javascript:void(0)">{{ subCategory.is_top ? 'Top' : 'Normal' }}</a>
                    </td>
                    <td class="td-cross" v-if="subCategory.status=='inactive'">
                      <span class="lnr lnr-cross-circle"></span>
                    </td>
                    <td class="td-check" v-else>
                      <span class="lnr lnr-checkmark-circle"> </span>
                    </td>

                    <td>
                      <ul>

                        <li>
                          <router-link :to="{name:'edit_sub_category', params:{ id: subCategory.id}}"> <i class="fas fa-edit text-success"></i> </router-link>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="pagination-box mb-5">
          <nav class="ms-auto me-auto" aria-label="...">
            <ul class="pagination pagination-primary">
              <Bootstrap5Pagination
                :data="subCategoriesData"
                @pagination-change-page="getSubCategories"
              />
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>



<style>
  .border-none{
    border: none;
  }
</style>
