
<script setup>
import axios from 'axios';
import {ref, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router';

const router   = useRouter();
const {params} = useRoute();

const shippedInfo = ref();
const loading     = ref(false);

const customer_name         = ref('');
const customer_phone_number = ref('');
const customer_address      = ref('');
const payable_price         = ref('');
const order_id              = ref('');
const order_note            = ref('');
const errors                = ref();

const getShippedOrderInfo = async() =>{
    try {
        const res = await axios.get(`/admin/stead/orders/shipped/${params.id}`);
        shippedInfo.value = res.data.result
        customer_name.value = res.data.result.order.customer_name
        customer_phone_number.value = res.data.result.order.phone_number
        customer_address.value = res.data.result.order.address_details
        payable_price.value = res.data.result.order.payable_price
        order_id.value = res.data.result.order.id
    } catch (error) {
        console.log(error);
    }
}

const submit = async() =>{
    loading.value = true;
    try {
        const res = await axios.post('/admin/stead-fast/create-order', {
            order_id : order_id.value,
            customer_name : customer_name.value,
            phone_number : customer_phone_number.value,
            address_details : customer_address.value,
            payable_price : payable_price.value,
            note : order_note.value,
        });
        if(res.status === 200){
            router.push({name:'order_list'});
        }else{
            errors.value = res.data.message;
        }
    } catch (error) {
        console.log(error);
    }finally{
        loading.value = false;
    }
}

onMounted(() => {
  getShippedOrderInfo();  
})
</script>



<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="submit">
            <div class="bg-inner cart-section order-details-table">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card-header-2 d-flex justify-content-between">
                            <h5>Shipped Street Fast Information</h5>
                            <router-link :to="{name:'order_list'}" class="btn btn-info"><i class="fa fa-arrow-left"></i> Back</router-link>
                        </div>
                    </div>
                </div>
                <div class="row box-shadow">
                    <div class="col-md-6 mb-4">
                        <label for="">Customer Name</label>
                        <input type="text" class="form-control" placeholder="Enter Recipient Name" v-model="customer_name">
                        <span v-if="errors">
                            <span class="text-danger" v-for="(error, index) in errors.customer_name" :key="index">{{error}}</span>
                        </span>
                    </div>
                    <div class="col-md-6 mb-4">
                        <label for="">Customer Phone Number</label>
                        <input type="text" class="form-control" placeholder="Enter Recipient Name" v-model="customer_phone_number">
                        <span v-if="errors">
                            <span class="text-danger" v-for="(error, index) in errors.phone_number" :key="index">{{error}}</span>
                        </span>
                    </div>
                    <div class="col-md-6 mb-4">
                        <label for="">Customer Address</label>
                        <input type="text" class="form-control" placeholder="Enter Recipient Name" v-model="customer_address">
                        <span v-if="errors">
                            <span class="text-danger" v-for="(error, index) in errors.address_details" :key="index">{{error}}</span>
                        </span>
                    </div>
                    <div class="col-md-6 mb-4">
                        <label for="">Payable Price</label>
                        <input type="text" class="form-control" placeholder="Enter Recipient Name" v-model="payable_price">
                        <span v-if="errors">
                            <span class="text-danger" v-for="(error, index) in errors.payable_price" :key="index">{{error}}</span>
                        </span>
                    </div>
                    <div class="col-md-6 mb-4">
                        <label for="">Note</label>
                        <textarea v-model="order_note" name="" id="" cols="30" rows="3" class="form-control"></textarea>
                    </div>
                    <div class="col-md-6 mb-4">
                        <div class=" shipped-btn">
                            <span>
                                <button class="btn btn-info" v-if="loading"><i class="fa fa-spinner fa-spin"></i> Loading....</button>
                                <button class="btn btn-info" v-else>Shipped to Streetfast</button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
    .box-shadow{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 20px;
        padding: 40px 30px;
    }
    .shipped-btn{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: end;
        justify-content: end;
    }
    .table th{
        font-size: 16px;
    }
</style>
